import React, { useRef, useEffect } from 'react';
import aboutUsCover from '../../Images/aboutUsCover.png';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AboudUsIntro = (props) => {

    const aboutUsRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();

    useEffect(() => {
        let vScrollTo = searchParams.get("scrollto");
        if (vScrollTo == "about") {
            if (aboutUsRef?.current) {
                aboutUsRef.current.scrollIntoView();
            }
        }
    }, [searchParams]);

    return (
        <div className='mainPadding' ref={aboutUsRef} style={{ minHeight: 186, background: 'rgba(39, 66, 80, 0.7)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 48, backgroundImage: 'url(' + aboutUsCover + ')', backgroundSize: 'cover', paddingTop: 32, paddingBottom: 32 }}>
            <div style={{ textAlign: 'center' }}>
                <div style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 24, color: '#FFFFFF' }}>
                    {t("Бидний тухай")}
                </div>
                <div style={{ marginTop: 24, fontWeight: 300, fontSize: 18, color: '#FFFFFF' }}>
                    {t("aboutUsIntroText")}
                </div>
            </div>
        </div>
    );
}

export default AboudUsIntro;