import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Header from './Page/Header/Header';
import Home from './Page/Home/Home';
import Footer from './Page/Footer/Footer';
import Products from './Page/Products/Products';
import Login from './Page/Login/Login';
import ProductDtl from './Page/ProductDtl/ProductDtl';
import AdminLogin from './Page/Admin/AdminLogin';
import { useLocation } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import AdminMain from './Page/Admin/AdminMain/AdminMain';
import { LoadScreen } from 'dw-components';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebaseApp } from './API/firebaseApp';
import UserInfo from './Page/UserInfo/UserInfo';
import Cart from './Page/Cart/Cart';
import { AdminAuthContext } from './Context/AdminAuthContext';
import { CartContext } from './Context/CartContext';
import News from './Page/News/News';
import NewsDtl from './Page/NewsDtl/NewsDtl';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { checkUserAuth } from './Const/SharedFunc';

function App() {

  const location = useLocation();
  const [adminAuth, setAdminAuth] = useState();
  const [cart, setCart] = useState();
  const [loading, setLoading] = useState(false);
  const [cartPrev, setCartPrev] = useState(false);

  const getData = async () => {
    const adminAuthResp = localStorage.getItem("admin");
    if (adminAuthResp) {
      setAdminAuth(JSON.parse(adminAuthResp));
    }

    const tUserAuth = checkUserAuth();
    if (tUserAuth?.uid) {
      getAuth(firebaseApp).onAuthStateChanged(function (user) {
      });
    }
  }

  useEffect(() => {
    getData();
    initializeIcons();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100wh', height: '100vh' }}>
        <LoadScreen />
      </div>
    );
  }

  if (location?.pathname?.indexOf("/admin") >= 0) {
    if (adminAuth?.idToken) {
      return (
        <AdminAuthContext.Provider value={{ adminAuth, setAdminAuth }}>
          <Routes>
            <Route path='/admin/*' element={<AdminMain />} />
            <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
            <Route path="*" element={<AdminLogin />} />
          </Routes>
        </AdminAuthContext.Provider>
      );
    } else {
      return (
        <AdminAuthContext.Provider value={{ adminAuth, setAdminAuth }}>
          <Routes>
            <Route path="*" element={<AdminLogin />} />
          </Routes>
        </AdminAuthContext.Provider>
      );
    }
  }

  return (
    <div>
      <CartContext.Provider value={{ cart, setCart, cartPrev, setCartPrev }}>
        <Header />
        <Routes>
          <Route path="/products" element={<Products />} />
          <Route path="/products/:id" element={<ProductDtl />} />
          <Route path='/login' element={<Login />} />
          <Route path="/userinfo/*" element={<UserInfo />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsDtl />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
        {window?.location?.hostname != 'localhost' && (
          <MessengerCustomerChat
            pageId="453871104680598"
            appId="1191718981627993"
          />
        )}
      </CartContext.Provider>
    </div>
  );
}

export default App;
