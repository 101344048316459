import React, { useEffect, useState } from 'react';
import loginBack from '../../Images/loginBack.png';
import { message, Tabs } from 'antd';
import ButtonBase from '../../Components/ButtonBase/ButtonBase';
import FieldNumber from '../../Components/FieldNumber/FieldNumber';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { firebaseApp } from '../../API/firebaseApp';
import { LoadSpin } from 'dw-components';
import OtpInput from 'react-otp-input';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { checkUserAuth } from '../../Const/SharedFunc';
import { get, getDatabase, ref, query, orderByChild, equalTo, push } from 'firebase/database';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;
const Login = (props) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const auth = getAuth(firebaseApp);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [pageType, setPageType] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        window.recaptchaVerifier = new RecaptchaVerifier('loginRecaptcha', {
            'size': 'invisible',
            'callback': (response) => {
            }
        }, auth);

        let tUserAuth = checkUserAuth();
        if (tUserAuth?.uid) {
            navigate("/userinfo/dtl");
        }
    }, []);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setData(tData);

            if (field == "otp" && key?.length == 6) {
                await onCheckOTP(key);
            }
        }
    }

    const onCheckOTP = async (pOtp) => {
        setLoading(true);
        const confirmationResult = window.confirmationResult;
        if (confirmationResult) {
            let success = false;
            let tUserAuth = {};
            await confirmationResult.confirm(pOtp).then((result) => {

                if (result.user) {
                    success = true;
                    message.success(t("Амжилттай"));
                    localStorage.setItem("userAuth", JSON.stringify(result.user));
                    tUserAuth = result.user;
                }
            }).catch((error) => {
                message.warn(t("Таны оруулсан код буруу байна"));
                setLoading(false);
            });

            if (success) {
                if (tUserAuth?.uid) {

                    let tUserExists = false;
                    await get(query(ref(getDatabase(firebaseApp), "user"), orderByChild("uid"), equalTo(tUserAuth?.uid))).then(response => {
                        let resp = response.toJSON();
                        if (resp) {
                            tUserExists = true;
                        }
                    }).catch(response => {
                        setLoading(false);
                    });

                    if (!tUserExists) {
                        await push(ref(getDatabase(firebaseApp), "user"), {
                            phone: tUserAuth?.phoneNumber,
                            created: moment().format("YYYY.MM.DD HH:mm:ss"),
                            uid: tUserAuth?.uid
                        }).then(response => {
                        }).catch(response => {
                            setLoading(false);
                        });
                    }

                }

                setLoading(false);

                if (searchParams.get("callback") == "cart") {
                    navigate("/cart");
                } else {
                    navigate("/userinfo/dtl");
                }

            } else {
                setLoading(false);
            }
        } else {
            message.warn(t("Баталгаажуулах код илгээгээгүй байна"));
        }
    }

    const onLogin = async () => {

        const appVerifier = window.recaptchaVerifier;
        if (data.phone && appVerifier) {
            setLoading(true);
            await signInWithPhoneNumber(auth, "+976" + data.phone?.toString(), appVerifier)
                .then((confirmationResult) => {
                    setPageType("otp");
                    setLoading(false);
                    message.success(t("Баталгаажуулах код илгээлээ"));
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    // ...
                }).catch((error) => {
                    message.warn(t("SMS илгээхэд алдаа гарлаа"));
                    setLoading(false);
                });
        } else {
            message.warn(t("Утасны дугаар оруулна уу!"));
        }
    }

    const onBackLogin = () => {
        setPageType();
    }

    let vPanel = null;

    if (pageType == "otp") {
        vPanel = (
            loading ? (
                <LoadSpin />
            ) : (
                <div>
                    <div style={{
                        fontWeight: 400,
                        fontSize: 16,
                        color: '#212121',
                        textAlign: 'center'
                    }}>
                        {t("Таны утсанд баталгаажуулах код илгээсэн")}
                    </div>
                    <div style={{ marginTop: 32 }}>
                        <OtpInput
                            inputStyle={{ width: 40, height: 40, borderRadius: 8, border: '1px solid #E7E7E7' }}
                            value={data.otp}
                            onChange={(otp) => fieldOnChanged(otp, "", "otp")}
                            numInputs={6}
                            separator={<span style={{ marginLeft: 8, marginRight: 8 }}>-</span>}
                        />
                    </div>
                    <div style={{ marginTop: 16 }}>
                        <ButtonBase second text={t("Буцах")} onClick={onBackLogin} />
                    </div>
                </div>
            ));
    } else {
        vPanel = (
            <div>
                <FieldNumber onChange={fieldOnChanged} title={t("Утасны дугаар")} keyfield="phone" value={data.phone} />
                <div style={{ marginTop: 40 }}>
                    {loading ? (
                        <LoadSpin />
                    ) : (
                        <div>
                            <ButtonBase text={t("НЭВТРЭХ")} onClick={onLogin} />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div style={{ padding: 65, backgroundImage: 'url(' + loginBack + ')', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className='login' style={{ width: 380, background: '#FFFFFF', boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.15)', borderRadius: 8, padding: 16 }}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t("Нэвтрэх")} key="1">
                        <div style={{ marginTop: 24 }}>
                            {vPanel}
                        </div>
                    </TabPane>
                </Tabs>
            </div>
            <div id="loginRecaptcha"></div>
        </div>
    );
}

export default Login;