import React, { useState, useEffect } from 'react';
import { PageHeader, GridDefaultShimmer, ColEdit } from 'dw-components';
import moment from 'moment';
import SimilarProductNew from './SimilarProductNew';

const SimilarProduct = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState();
    const [showNew, setShowNew] = useState(false);

    useEffect(() => {
        let tData = [];
        if (props.defaultValue?.length > 0) {
            tData = props.defaultValue;
        }
        setData(tData);
    }, [props.defaultValue]);

    const [columns, setColumns] = useState([
        {
            name: 'Барааны нэр',
            fieldName: 'name',
            onRender: (item) => {
                return (
                    <ColEdit onClick={() => onEdit(item.id)}>{item.name}</ColEdit>
                );
            }
        }
    ]);

    const onEdit = (pId) => {
        setId(pId);
        setShowNew(true);
    }

    const onNew = () => {
        setId(moment().unix());
        setShowNew(true);
    }

    const onDismiss = () => {
        setId(moment().unix());
        setShowNew(false);
    }

    const onAfterSave = (pData) => {
        let tData = [...data];
        if (pData?.id) {
            let tIndex = tData?.map(r => r.id).indexOf(pData.id);
            if (tIndex >= 0) {
                tData[tIndex] = pData;
            } else {
                tData.push(pData);
            }
        }
        if (props.onChanged) {
            props.onChanged(tData, null, props.keyfield, props.keyfieldname);
        }
    }

    const onDelete = (pId) => {
        let tData = [...data];
        if (pId) {
            let tIndex = tData?.map(r => r.id).indexOf(pId);
            if (tIndex >= 0) {
                tData.splice(tIndex, 1);
            }
        }
        if (props.onChanged) {
            props.onChanged(tData, null, props.keyfield, props.keyfieldname);
        }
        onDismiss();
    }

    return (
        <div>
            <PageHeader
                title={props.title}
                buttonTitle={props.title + " нэмэх"}
                onButtonClick={onNew}
                dataCount={data?.length}
            />
            <GridDefaultShimmer
                data={data}
                columns={columns}
                loading={loading}
            />
            <SimilarProductNew
                isOpen={showNew}
                onDismiss={onDismiss}
                id={id}
                data={data}
                onAfterSave={onAfterSave}
                onDelete={onDelete}
            />
        </div>
    );
}

export default SimilarProduct;