import { Col, Row } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import noImage from '../../../Images/noImage.png';

const SimilarProduct = (props) => {

    const navigate = useNavigate();

    const onProduct = (pId) => {
        if (pId) {
            navigate("/products/" + pId);
        }
    }

    return (
        <div style={{ width: '100%' }}>
            {props.index == 0 && (
                <Row style={{ background: '#FFFFFF' }}>
                    <Col span={6}>
                    </Col>
                    {props?.data?.value1 && (
                        <Col span={6} style={{ borderLeft: '1px solid #000000', borderRight: '1px solid #000000', borderTop: '1px solid #000000' }}>
                            <div style={{ textAlign: 'center', padding: 14 }}>
                                {props?.data?.img1 ? (
                                    <img className='similarImage' style={{ imageRendering: 'pixelated' }} src={props?.data?.img1} />
                                ) : (
                                    <img className='similarImage' src={noImage} alt="" />
                                )}
                                <div className='fontMidV2' style={{ fontWeight: 500, color: '#212121', marginTop: 16 }}>
                                    {props?.data?.value1product}
                                </div>
                            </div>
                        </Col>
                    )}
                    {props?.data?.value2 && (
                        <Col span={6} style={{ borderLeft: '1px solid rgb(231, 231, 231)' }}>
                            <div style={{ textAlign: 'center', cursor: 'pointer', padding: 14 }} onClick={() => onProduct(props?.data?.productid2)}>
                                {props?.data?.img2 ? (
                                    <img className='similarImage' style={{ imageRendering: 'pixelated' }} src={props?.data?.img2} />
                                ) : (
                                    <img className='similarImage' src={noImage} alt="" />
                                )}
                                <div className='fontMidV2' style={{ fontWeight: 500, color: '#212121', marginTop: 16 }}>
                                    {props?.data?.value2product}
                                </div>
                            </div>
                        </Col>
                    )}
                    {props?.data?.value3 && (
                        <Col span={6} style={{ borderLeft: '1px solid rgb(231, 231, 231)' }}>
                            <div style={{ textAlign: 'center', cursor: 'pointer', padding: 14 }} onClick={() => onProduct(props?.data?.productid3)}>
                                {props?.data?.img3 ? (
                                    <img className='similarImage' style={{ imageRendering: 'pixelated' }} src={props?.data?.img3} />
                                ) : (
                                    <img className='similarImage' src={noImage} alt="" />
                                )}
                                <div className='fontMidV2' style={{ fontWeight: 500, color: '#212121', marginTop: 16 }}>
                                    {props?.data?.value3product}
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            )}
            <Row>
                <Col span={6}>
                    <div className='fontMidV2' style={{ fontWeight: 500, color: '#212121', padding: '10px 16px' }}>
                        {props?.data?.specname}
                    </div>
                </Col>
                <Col span={6} style={{ borderLeft: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: (props?.index + 1 == props?.total) ? '1px solid #000000' : '0px', textAlign: 'center', background: Number.isInteger((props.index + 1) / 2) ? '#FFFFFF' : 'rgba(255, 163, 0, 0.1)' }}>
                    <div className='fontMidV2' style={{ fontWeight: 400, color: '#212121', padding: '10px 16px' }}>
                        {props?.data?.value1 ? props?.data?.value1 : "--"}
                    </div>
                </Col>
                <Col span={6} style={{ borderLeft: '1px solid rgb(231, 231, 231)', textAlign: 'center' }}>
                    <div className='fontMidV2' style={{ fontWeight: 400, color: '#212121', padding: '10px 16px' }}>
                        {props?.data?.value2 ? props?.data?.value2 : "--"}
                    </div>
                </Col>
                <Col span={6} style={{ borderLeft: '1px solid rgb(231, 231, 231)', textAlign: 'center' }}>
                    <div className='fontMidV2' style={{ fontWeight: 400, color: '#212121', padding: '10px 16px' }}>
                        {props?.data?.value3 ? props?.data?.value3 : "--"}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default SimilarProduct;