import React, { useEffect, useState } from 'react';
import { ButtonDefault, FieldCheck, FieldNumber, FieldText, LoadSpin, PanelBase, FieldDropdown } from 'dw-components';
import moment from 'moment';
import { firebaseApp } from '../../../../API/firebaseApp';
import { message } from 'antd';
import { get, getDatabase, ref } from 'firebase/database';

const SimilarProductNew = (props) => {

    const [initData, setInitData] = useState({
        id: props.id,
        productid: null,
        name: null,
        specifications: null,
        created: moment().format("YYYY.MM.DD HH:mm:ss")
    });
    const [data, setData] = useState({});
    const [checkRule, setCheckRule] = useState(false);
    const [rowIndex, setRowIndex] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [dProduct, setDProduct] = useState([]);


    const getData = async () => {
        let tData = { ...initData };
        let tIndex = -1;
        if (props.id && props.data?.length > 0) {

            tIndex = props.data.map(r => r.id).indexOf(props.id);

            if (tIndex >= 0) {
                tData = props.data[tIndex];
            }
        }
        setData(tData);
        setRowIndex(tIndex);
    }

    const getInitData = async () => {
        setLoading(true);
        let tDProdcut = [];
        await get(ref(getDatabase(firebaseApp), "product")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {

                    let tSpecifications = [];

                    if (resp[key]?.specifications) {
                        Object.keys(resp[key].specifications)?.map(keyspec => {
                            tSpecifications.push({
                                text: resp[key].specifications[keyspec].name,
                                value: resp[key].specifications[keyspec].body
                            });
                        });
                    }

                    tDProdcut.push({
                        key: key,
                        text: resp[key]?.name,
                        specifications: tSpecifications,
                        img: resp[key]?.img ? resp[key]?.img : ""
                    });
                });
            }
        });
        setDProduct(tDProdcut);
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [props.id]);

    useEffect(() => {
        getInitData();
    }, []);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }

            if (field == "productid") {
                let tIndex = dProduct?.map(r => r.key).indexOf(key);
                if (tIndex >= 0) {
                    tData.specifications = dProduct[tIndex].specifications;
                    tData.img = dProduct[tIndex].img;
                }
            }
            setData(tData);
        }
    }

    const onSave = async () => {

        setCheckRule(true);
        let vRule = true;

        if (!data.name
            || !data.productid) {
            vRule = false;
        }

        if (vRule) {

            let tData = { ...data };

            if (rowIndex < 0) {
                tData.id = props.id;
                tData.created = moment().format("YYYY.MM.DD HH:mm:ss");
            }
            if (props.onAfterSave) {
                props.onAfterSave(tData);
            }
            onDismiss();
        }
    }

    const onDismiss = () => {
        setCheckRule(false);
        setData({ ...initData });
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    return (
        <div>
            <PanelBase
                delete
                onDelete={() => props.onDelete(props.id)}
                isOpen={props.isOpen}
                onDismiss={onDismiss}
                headerText={rowIndex >= 0 ? "Ижил төстэй бараа засах" : "Ижил төстэй бараа нэмэх"}
                buttons={(
                    <ButtonDefault onClick={onSave} text="Хадгалах" />
                )}
            >
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown loading={loading} required title="Барааны нэр" onChanged={fieldOnChanged} keyfield='productid' keyfieldname='name' defaultValue={data?.productid} checkreq={checkRule} data={dProduct} />
                </div>
            </PanelBase>
        </div>
    );
}

export default SimilarProductNew;