import React, { useState } from 'react';
import Slider from "react-slick";
import SliderItem from '../../Components/SliderItem/SliderItem';
import slideImg1 from '.././../Images/Cover/cover1.png';


const HomeSlider = (props) => {

    const [settings, setSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    return (
        <div style={{ maxWidth: '100vw', overflow: 'hidden' }}>
            <Slider {...settings}>
                <div>
                    <SliderItem img={slideImg1} />
                </div>
            </Slider>
        </div>
    );
}

export default HomeSlider;