import React from 'react';

const OrderInfo = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 7V5C7 3.34315 8.34315 2 10 2C10.7684 2 11.4692 2.28886 12 2.7639C12.5308 2.28885 13.2317 2 14 2C15.6569 2 17 3.34315 17 5V7H18.5C19.3284 7 20 7.67157 20 8.5V18.505C20 20.4352 18.4352 22 16.505 22H8C5.79086 22 4 20.2091 4 18V8.5C4 7.67157 4.67157 7 5.5 7H7ZM13.635 20.5C13.241 19.9343 13.01 19.2466 13.01 18.505V8.5H5.5V18C5.5 19.3807 6.61929 20.5 8 20.5H13.635ZM11.5 7V5C11.5 4.17157 10.8284 3.5 10 3.5C9.17157 3.5 8.5 4.17157 8.5 5V7H11.5ZM13 7H15.5V5C15.5 4.17157 14.8284 3.5 14 3.5C13.535 3.5 13.1195 3.71156 12.8444 4.04368C12.9453 4.34403 13 4.66563 13 5V7ZM14.51 18.505C14.51 19.6068 15.4032 20.5 16.505 20.5C17.6068 20.5 18.5 19.6068 18.5 18.505V8.5H14.51V18.505Z" fill={props.active ? "#FFA300" : "#212121"} />
        </svg>
    );
}

export default OrderInfo;