import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as Goal1 } from '../../Svg/goal1.svg';
import { ReactComponent as Goal2 } from '../../Svg/goal2.svg';
import { ReactComponent as Goal3 } from '../../Svg/goal3.svg';
import { useTranslation } from 'react-i18next';

const GoalIntro = (props) => {

    const styles = {
        container: {
            background: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.15)',
            borderRadius: 12,
            padding: '12px 24px 12px 24px',
            width: '100%',
            height: '100%'
        },
        title: {
            fontFamily: 'Montserrat',
            fontWeight: 700,
            fontSize: 20,
            color: '#FFA300'
        },
        body: {
            fontWeight: 300,
            fontSize: 16,
            color: '#212121',
        },
        bold: {
            fontWeight: 500
        }
    };

    const { t } = useTranslation();

    return (
        <div className='mainPadding' style={{ paddingTop: 48, paddingBottom: 48, backgroundColor: '#F8F8F8' }}>
            <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 8 }} xxl={{ span: 8 }} >
                    <div style={{ ...styles.container }}>
                        <div style={{ marginRight: 16 }}>
                            <Goal1 />
                        </div>
                        <div>
                            <div style={{ ...styles.title }}>
                                {t("ЗОРИЛГО")}
                            </div>
                            <div style={{ ...styles.body }}>
                                <span style={{ ...styles.bold }}>Харилцагчаа</span> дээдэлсэн, <span style={{ ...styles.bold }}>чанарыг</span> эрхэмлэсэн, олон улсын <span style={{ ...styles.bold }}>стандартыг</span> хангасан бүтээгдэхүүний үйлдвэрлэл, үйлчилгээ үзүүлэх
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
                    <div style={{ ...styles.container }}>
                        <div style={{ marginRight: 16 }}>
                            <Goal2 />
                        </div>
                        <div>
                            <div style={{ ...styles.title }}>
                                {t("АЛСЫН ХАРАА")}
                            </div>
                            <div style={{ ...styles.body }}>
                                Үйлчлүүлэгчдийнхээ <span style={{ ...styles.bold }}>бүтээмжийг</span> нэмэгдүүлэхийн тулд үргэлж <span style={{ ...styles.bold }}>шинийг</span> эрэлхийлэх
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
                    <div style={{ ...styles.container }}>
                        <div style={{ marginRight: 16 }}>
                            <Goal3 />
                        </div>
                        <div>
                            <div style={{ ...styles.title }}>
                                {t("ҮНЭТ ЗҮЙЛ")}
                            </div>
                            <div style={{ ...styles.body }}>
                                Аливаад <span style={{ ...styles.bold }}>зөв</span> байх Ажиллагсадтайгаа <span style={{ ...styles.bold }}>хамт хөгжин</span>, үр дүнтэй ажиллахын тулд <span style={{ ...styles.bold }}>багийн ажиллагааг</span> эрхэмлэх
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default GoalIntro; 