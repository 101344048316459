import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import Product from '../../Components/Product/Product';
import { LoadScreen } from 'dw-components';
import { get, getDatabase, ref } from 'firebase/database';
import { firebaseApp } from '../../API/firebaseApp';
import Slider from "react-slick";
import { ReactComponent as LeftIcon } from '../../Svg/left.svg';
import { ReactComponent as RightIcon } from '../../Svg/right.svg';

const { TabPane } = Tabs;
const ProductIntro = (props) => {

    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(false);

    const settings = {
        dots: true,
        speed: 500,
        slidesToScroll: 4,
        slidesToShow: 4,
        infinite: false,
        nextArrow: <RightIcon />,
        prevArrow: <LeftIcon />,
        responsive: [
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            }, {
                breakpoint: 577,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            }, {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 993,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 1201,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const getData = async () => {
        setLoading(true);

        let tCategory = [];
        let tProduct = [];

        await get(ref(getDatabase(firebaseApp), "category")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    if (resp[key]?.active) {
                        tCategory.push({ id: key, ...resp[key] });
                    }
                });
            }
        });

        await get(ref(getDatabase(firebaseApp), "product")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    if (resp[key]?.active) {
                        tProduct.push({ id: key, ...resp[key] });
                    }
                });
            }
        });

        tCategory.sort((a, b) => {
            if (a.created < b.created) {
                return -1;
            }
            if (a.created > b.created) {
                return 1;
            }
            return 0;
        });

        tCategory?.map(r => {
            let tCatProduct = [];
            tProduct?.map((ri, index) => {
                if (ri.categoryid == r.id) {
                    tCatProduct.push(ri);
                }
            });
            r.product = tCatProduct;
        });

        setCategory(tCategory);
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    return (
        <div className='mainPadding' style={{ marginTop: 48 }}>
            <Tabs defaultActiveKey="0" centered>
                {category?.map((r, index) => {
                    return (
                        <TabPane tab={r.name} key={index?.toString()}>
                            <div>
                                <Slider {...settings}>
                                    {r?.product?.map((ri, indexi) => {
                                        return (
                                            <div key={indexi}>
                                                <Product data={ri} />
                                            </div>
                                        );
                                    })}
                                </Slider>
                                {(!r?.product || r?.product?.length) == 0 && (
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div style={{ flex: 1, textAlign: 'center', color: '#BABABA', paddingBottom: 18 }}>
                                            Мэдээлэл байхгүй
                                        </div>
                                    </div>
                                )}
                            </div>
                        </TabPane>
                    );
                })}
            </Tabs>
        </div >
    );
}

export default ProductIntro;