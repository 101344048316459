import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../Svg/logo.svg';
import { ReactComponent as Cart } from '../../Svg/cart.svg';
import { ReactComponent as User } from '../../Svg/user.svg';
import { ReactComponent as VerticalDivider } from '../../Svg/verticalDivider.svg';
import HeaderText from '../../Components/HeaderText/HeaderText';
import { useNavigate } from 'react-router-dom';
import { Select, Dropdown, Menu, Popover } from 'antd';
import enPng from '../../Images/en.png';
import mnPng from '../../Images/mn.png';
import moment from 'moment';
import { CartContext } from '../../Context/CartContext';
import CartCounter from '../../Components/CartCounter/CartCounter';
import { ReactComponent as MenuIcon } from '../../Svg/menu.svg';
import { getCart } from '../../Const/SharedFunc';
import CartPrev from '../../Components/CartPrev/CartPrev';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const Header = (props) => {

    const { t, i18n } = useTranslation();
    const { cart, setCart, cartPrev, setCartPrev } = useContext(CartContext);
    const navigate = useNavigate();

    const [lang, setlang] = useState("mn");

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: <div onClick={() => onNavigate("")}>Нүүр хуудас</div>
                },
                {
                    key: '2',
                    label: <div onClick={() => scrollTo("about")}>Бидний тухай</div>
                },
                {
                    key: '3',
                    label: <div onClick={() => scrollTo("history")}>Түүхэн замнал</div>
                }, {
                    key: '4',
                    label: <div onClick={() => onNavigate("products")}>Бүтээгдэхүүн</div>
                }
            ]}
        />
    );

    const getData = () => {
        let tCart = getCart();
        setCart(tCart);
    }

    useEffect(() => {
        getData();
    }, []);

    const onNavigate = (p_value) => {
        navigate("/" + p_value);
    }

    const styles = {
        text: {
            fontWeight: 500,
            fontSize: 14,
            color: '#FFFFFF',
            cursor: 'pointer'
        }
    }

    const onChangeLang = (pLang) => {
        setlang(pLang);
        i18n.changeLanguage(pLang);
    }

    const scrollTo = (p_value) => {
        navigate("/?scrollto=" + p_value + "&id=" + moment().unix());
    }


    return (
        <div>
            <div className='mainPadding' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 36, background: '#233153' }}>
                <div style={{ display: 'flex' }}>
                    <div onClick={() => onNavigate("news")} style={{ ...styles.text, color: '#FFA300' }}> {t("Зөвлөгөө, мэдээлэл")}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: 20, height: 20 }}>
                        {lang == "mn" ? (
                            <img style={{ width: 20, height: 20, imageRendering: 'pixelated' }} src={mnPng} alt='' />
                            ) : (
                            <img style={{ width: 20, height: 20, imageRendering: 'pixelated' }} src={enPng} alt='' />
                        )}
                    </div>
                    <div>
                        <Select onChange={onChangeLang} defaultValue="mn" style={{ width: 70, color: '#FFFFFF' }} bordered={false}>
                            <Option value="en">EN</Option>
                            <Option value="mn">MN</Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div className='mainPadding' style={{ height: 64, boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', display: 'flex', justifyContent: 'space-between' }}>
                <div className='smallMenu' style={{ display: 'flex', alignItems: 'center' }}>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <div>
                            <MenuIcon />
                        </div>
                    </Dropdown>
                </div>
                <div onClick={() => onNavigate("")} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Logo />
                </div>
                <div className='mainMenu' style={{ display: 'flex', alignItems: 'center' }}>
                    <HeaderText onClick={() => onNavigate("")}>
                        {t("Нүүр хуудас")}
                    </HeaderText>
                    <HeaderText onClick={() => scrollTo("about")}>
                        {t("Бидний тухай")}
                    </HeaderText>
                    <HeaderText onClick={() => scrollTo("history")}>
                        {t("Түүхэн замнал")}
                    </HeaderText>
                    <VerticalDivider />
                    <HeaderText onClick={() => onNavigate("products")}>
                        {t("Бүтээгдэхүүн")}
                    </HeaderText>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ height: 24, cursor: 'pointer' }} onClick={() => onNavigate("cart")}>
                        <CartCounter data={cart} />
                        <Popover placement='bottomLeft' trigger="click" visible={cartPrev} onVisibleChange={() => { setCartPrev(false); }} content={<CartPrev />}>
                            <Cart />
                        </Popover>
                    </div>
                    <div style={{ height: 24, marginLeft: 24, cursor: 'pointer' }} onClick={() => onNavigate("login")}>
                        <User />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Header;