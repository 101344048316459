import React from 'react';
import HomeSlider from '../HomeSlider/HomeSlider';
import ProductIntro from '../ProductIntro/ProductIntro';
import AboudUsIntro from '../AboudUsIntro/AboudUsIntro';
import GoalIntro from '../GoalIntro/GoalIntro';
import TeamsIntro from '../TeamsIntro/TeamsIntro';
import Footer from '../Footer/Footer';
import TimelineIntro from '../TimelineIntro/TimelineIntro';

const Home = (props) => {
    return (
        <div>
            <HomeSlider />
            <ProductIntro />
            <AboudUsIntro />
            <GoalIntro />
            <TeamsIntro />
            <TimelineIntro />
        </div>
    );
}

export default Home;