import React from 'react';
import { ReactComponent as Cart } from '../../Svg/cartButton.svg';
import { LoadSpin } from 'dw-components';
import { useTranslation } from 'react-i18next';

const ButtonCart = (props) => {
    const { t } = useTranslation();

    if (props.loading) {
        return (
            <LoadSpin />
        );
    } else {
        return (
            <div className='cartButton' style={{ height: 40, background: '#FFA300', borderRadius: 24, paddingLeft: 16, paddingRight: 16, display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={props.onClick}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Cart />
                </div>
                <div style={{ fontWeight: 400, fontSize: 14, color: '#F9F9F9', marginLeft: 8 }}>
                    {t("Сагсанд нэмэх")}
                </div>
            </div>
        );
    }
}

export default ButtonCart;