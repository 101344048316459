import React, { useRef, useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { useSearchParams } from 'react-router-dom';
import { get, getDatabase, ref } from 'firebase/database';
import { firebaseApp } from '../../API/firebaseApp';
import { useTranslation } from 'react-i18next';

const TimelineIntro = (props) => {

    const timelineIntoRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState([]);
    const { t } = useTranslation();

    const getData = async () => {
        let tData = [];

        await get(ref(getDatabase(firebaseApp), "timeline")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    tData.push({ id: key, ...resp[key] });
                });
            }
        });

        tData.sort((a, b) => {
            if (a.orderby < b.orderby) {
                return -1;
            }
            if (a.orderby > b.orderby) {
                return 1;
            }
            return 0;
        });

        setData(tData);
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        let tScrollto = searchParams.get("scrollto");
        if (tScrollto == "history") {
            if (timelineIntoRef?.current) {
                timelineIntoRef.current.scrollIntoView();
            }
        }
    }, [searchParams]);

    const styles = {
        time: {
            background: '#FFA300',
            padding: '2px 12px 2px 12px',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: 14,
            fontWeight: 400,
            fontSize: 16,
            color: '#FFFFFF'
        },
        text: {
            fontWeight: 300,
            fontSize: 16,
            color: '#000000'
        }
    }

    return (
        <div className='mainPadding' ref={timelineIntoRef} style={{ marginTop: 32 }}>
            <div style={{ textAlign: 'center', fontFamily: 'Montserrat', fontWeight: 700, fontSize: 24, color: '#000000' }}>
                {t("Түүхэн замнал")}
            </div>
            <div style={{ marginTop: 32, marginBottom: 32 }}>
                <Timeline position="alternate">

                    {data?.map((r, index) => {
                        return (
                            <TimelineItem key={r.id}>
                                <TimelineOppositeContent>
                                    <div style={{ display: 'flex', justifyContent: Number.isInteger(index / 2) ? 'flex-end' : 'flex-start' }}>
                                        <div style={{ ...styles.time }}>
                                            {r.name}
                                        </div>
                                    </div>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    {data?.length != index + 1 && < TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent>
                                    <div style={{ ...styles.text }}>
                                        {r.description}
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })}
                </Timeline>
            </div>
        </div>
    );
}

export default TimelineIntro;