import React, { useEffect, useState } from 'react';
import { Row, Col, List } from 'antd';
import { ReactComponent as ClockIcon } from '../../Svg/clock.svg';
import { ReactComponent as VisitIcon } from '../../Svg/visit.svg';
import NewsList from '../../Components/NewsList/NewsList';
import { useParams } from 'react-router-dom';
import { LoadScreen } from 'dw-components';
import { get, getDatabase, ref, update } from 'firebase/database';
import { firebaseApp } from '../../API/firebaseApp';

const NewsDtl = (props) => {

    const params = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);

    const getData = async () => {
        let tData = {};
        let tList = [];

        if (params.id) {
            setLoading(true);
            await get(ref(getDatabase(firebaseApp), "news/" + params.id)).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    tData = { id: params.id, ...resp };
                }
            }).catch(response => {
                setLoading(false);
            });

            await update(ref(getDatabase(firebaseApp), "news/" + params.id), {
                visitcount: tData.visitcount + 1
            }).then(response => {
            }).catch(response => {
                setLoading(false);
            });

            await get(ref(getDatabase(firebaseApp), "news")).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    Object.keys(resp)?.map(key => {
                        if (resp[key].active && key != tData?.id) {
                            tList.push({ id: key, ...resp[key] });
                        }
                    });
                }
            }).catch(response => {
                setLoading(false);
            });

            setLoading(false);

            tList?.sort((a, b) => {
                if (a.created > b.created) {
                    return -1;
                }
                if (a.created < b.created) {
                    return 1;
                }
                return 0;
            });

        }
        setData(tData);
        setList(tList);
    }

    useEffect(() => {
        getData();
    }, [params?.id]);




    if (loading) {
        return (
            <LoadScreen />
        );
    }

    return (
        <div className='mainPadding' style={{ paddingTop: 38, paddingBottom: 38 }}>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 16 }} xxl={{ span: 16 }}>
                    <div style={{ fontFamily: 'Montserrat', fontWeight: 700, fontSize: 20, color: '#212121' }}>{data?.title}</div>
                    <div style={{ marginTop: 12, fontWeight: 400, fontSize: 14, color: '#212121', textAlign: 'justify' }}>
                        {data?.body}
                    </div>
                    <div style={{ marginTop: 12 }}>
                        <img style={{ width: '100%' }} src={data?.img} />
                    </div>
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
                    <List
                        itemLayout='horizontal'
                        dataSource={list}
                        locale={{
                            emptyText: 'Мэдээлэл байхгүй'
                        }}
                        renderItem={(item, indedx) => {
                            return (
                                <List.Item>
                                    <NewsList small data={item} />
                                </List.Item>
                            )
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default NewsDtl;