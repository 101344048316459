import { Col, List, Row } from 'antd';
import React, { useState } from 'react';
import Employee from '../../Components/Employee/Employee';
import { useTranslation } from 'react-i18next';

const TeamsIntro = (props) => {

    const { t } = useTranslation();

    const [data, setData] = useState([{
        name: t("Д.Болд"),
        description: t("Гүйцэтгэх захирал"),
        img: 'https://firebasestorage.googleapis.com/v0/b/oresh-order.appspot.com/o/temp%2Femp1.png?alt=media&token=02516f01-5db8-4a95-b5a2-305666f53d74'
    },
    {
        name: t("Д.Болормаа"),
        description: t("Ерөнхий менежер"),
        img: 'https://firebasestorage.googleapis.com/v0/b/oresh-order.appspot.com/o/temp%2Femp2.png?alt=media&token=21e38d29-f71c-471f-9058-c923c31ca7e9'
    },
    {
        name: t("Л.Нармандах"),
        description: t("Үйлдвэрийн дарга"),
        img: 'https://firebasestorage.googleapis.com/v0/b/oresh-order.appspot.com/o/temp%2Femp3.png?alt=media&token=0297d328-aa7c-4c81-ba08-1f8524b99f9a'
    },
    {
        name: t("Н.Саруул"),
        description: t("Маркетингийн менежер"),
        img: 'https://firebasestorage.googleapis.com/v0/b/oresh-order.appspot.com/o/temp%2Femp5.png?alt=media&token=034be5ca-3623-44b6-a848-95dd4119425c'
    },
    {
        name: t("Э.Өсөхбаяр"),
        description: t("Гадаад харилцааны менежер"),
        img: 'https://firebasestorage.googleapis.com/v0/b/oresh-order.appspot.com/o/temp%2Femp6.png?alt=media&token=f1d0b8b4-8b21-4060-9ca5-fa284e43c66a'
    }]);

    const [data2, setData2] = useState([
        {
            name: t("Л.Шүрэнцэцэг"),
            description: t("Худалдааны зөвлөх"),
            img: 'https://firebasestorage.googleapis.com/v0/b/oresh-order.appspot.com/o/temp%2Femp5.png?alt=media&token=034be5ca-3623-44b6-a848-95dd4119425c'
        },
        {
            name: t("Б.Ангар"),
            description: t("Худалдааны зөвлөх"),
            img: 'https://firebasestorage.googleapis.com/v0/b/oresh-order.appspot.com/o/temp%2Femp4.png?alt=media&token=32dbf3b5-fa56-4183-8737-dff8092ada54'
        }
    ]);

    return (
        <div className='mainPadding' style={{ backgroundColor: '#F8F8F8', paddingBottom: 48 }}>
            <div style={{ textAlign: 'center', fontFamily: 'Montserrat', fontWeight: 700, fontSize: 24 }}>{t("Манай баг")}</div>
            <div style={{ paddingTop: 40 }}>
                <Row gutter={[32, 32]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 16 }} xxl={{ span: 16 }} >
                        <div>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ paddingBottom: 4, fontFamily: 'Montserrat', fontWeight: 700, fontSize: 24, marginTop: -16 }}>{t("Удирдлагын баг")}</div>
                                <div style={{ borderTop: '1px solid #767676', borderRight: '1px solid #767676', flex: 1, marginLeft: 16, height: 12 }}></div>
                            </div>
                            <div style={{ marginTop: 24 }}>
                                <List
                                    itemLayout='horizontal'
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 2,
                                        md: 2,
                                        lg: 3,
                                        xl: 3,
                                        xxl: 4,
                                    }}
                                    dataSource={data}
                                    renderItem={(item, index) => {
                                        return (
                                            <List.Item>
                                                <Employee key={index} data={item} />
                                            </List.Item>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 8 }} xxl={{ span: 8 }} >
                        <div>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ paddingBottom: 4, fontFamily: 'Montserrat', fontWeight: 700, fontSize: 24, marginTop: -16 }}>{t("Борлуулалтын баг")}</div>
                                <div style={{ borderTop: '1px solid #767676', borderRight: '1px solid #767676', flex: 1, marginLeft: 16, height: 12 }}></div>
                            </div>
                            <div style={{ marginTop: 24 }}>
                                <List
                                    itemLayout='horizontal'
                                    dataSource={data2}
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 1,
                                        md: 1,
                                        lg: 1,
                                        xl: 1,
                                        xxl: 1,
                                    }}
                                    renderItem={(item, index) => {
                                        return (
                                            <List.Item>
                                                <Employee key={index} data={item} />
                                            </List.Item>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default TeamsIntro;