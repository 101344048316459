import React, { useState, useEffect, useContext } from 'react';
import { Breadcrumb, Col, List, message, Row } from 'antd';
import NumberBase from '../../Components/NumberBase/NumberBase';
import ButtonAddReduce from '../../Components/ButtonAddReduce/ButtonAddReduce';
import ButtonCart from '../../Components/ButtonCart/ButtonCart';
import Product from '../../Components/Product/Product';
import ProductImages from './ProductImages/ProductImages';
import { useNavigate, useParams } from 'react-router-dom';
import { get, getDatabase, push, ref, query, orderByChild, equalTo, update, set } from 'firebase/database';
import { firebaseApp } from '../../API/firebaseApp';
import { LoadScreen } from 'dw-components';
import { CartContext } from '../../Context/CartContext';
import FieldDropdown from '../../Components/FieldDropdown/FieldDropdown';
import SimilarProduct from './SimilarProduct/SimilarProduct';
import noImage from '../../Images/noImage.png';
import { getCart } from '../../Const/SharedFunc';
import { useTranslation } from 'react-i18next';

const ProductDtl = () => {

    const navigate = useNavigate();
    const params = useParams();

    const { cart, setCart, setCartPrev } = useContext(CartContext);

    const [initData, setInitData] = useState({ qty: 1 });
    const [data, setData] = useState({});
    const [dataCat, setDataCat] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingCart, setLoadingCart] = useState(false);
    const [dUseProduct, setDUseProduct] = useState([]);
    const [dProductType, setDProductType] = useState([]);
    const [dOProductType, setDOProductType] = useState([]);
    const [dSimilar, setDSimilar] = useState([]);
    const { t } = useTranslation();

    const getData = async () => {
        let tData = { ...initData };
        let tDataCat = [];
        let tDProductType = [];
        let tDSimilar = [];
        let tDUseProduct = [];

        if (params.id) {
            setLoading(true);
            await get(ref(getDatabase(firebaseApp), "product/" + params.id)).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    tData = { id: params.id, ...resp, qty: 1 };

                    let tSpecifications = [];
                    if (tData?.specifications) {
                        Object.keys(tData.specifications)?.map(key => {
                            tSpecifications.push({
                                text: tData.specifications[key].name,
                                value: tData.specifications[key].body
                            });
                        });
                    }
                    tData.specifications = tSpecifications;

                    let tProductType = [];
                    if (tData?.producttype) {
                        Object.keys(tData.producttype)?.map(key => {
                            tProductType.push(tData.producttype[key]);
                            tDProductType.push({
                                key: tData.producttype[key].id,
                                text: tData.producttype[key].name,
                                productid: tData.producttype[key].productid,
                                productname: tData.producttype[key].productname
                            });

                            if (tData.producttype[key].productid) {
                                if (tDUseProduct?.map(r => r.key).indexOf(tData.producttype[key].productid) < 0) {
                                    tDUseProduct.push({
                                        key: tData.producttype[key].productid,
                                        text: tData.producttype[key].productname
                                    });
                                }
                            }
                        });
                    }
                    tData.producttype = tProductType;

                    let tSimilar = [];
                    if (tData?.similar) {
                        Object.keys(tData.similar)?.map(key => {
                            let tSpecSimilar = [];
                            if (tData.similar[key]?.specifications) {
                                Object.keys(tData.similar[key]?.specifications)?.map(keysim => {
                                    tSpecSimilar.push({
                                        text: tData.similar[key].specifications[keysim].text,
                                        value: tData.similar[key].specifications[keysim].value
                                    });
                                });
                            }
                            tSimilar.push({ ...tData.similar[key], specifications: tSpecSimilar });
                        });
                    }

                    if (tSpecifications?.length > 0 && tSimilar?.length > 0) {
                        tSpecifications?.map(rspec => {

                            let tValue2 = null;
                            let tValue2Product = null;
                            let img2 = null;
                            let tProductId2 = null;

                            let tValue3 = null;
                            let tValue3Product = null;
                            let img3 = null;
                            let tProductId3 = null;

                            if (tSimilar.length > 0) {
                                let tIndex = tSimilar[0]?.specifications?.map(rspecsim => rspecsim.text).indexOf(rspec.text);
                                if (tIndex >= 0) {
                                    tValue2 = tSimilar[0]?.specifications[tIndex].value;
                                }
                                tValue2Product = tSimilar[0]?.name;
                                img2 = tSimilar[0]?.img;
                                tProductId2 = tSimilar[0]?.productid;
                            }

                            if (tSimilar.length > 1) {
                                let tIndex = tSimilar[1]?.specifications?.map(rspecsim => rspecsim.text).indexOf(rspec.text);
                                if (tIndex >= 0) {
                                    tValue3 = tSimilar[1]?.specifications[tIndex].value;
                                }
                                tValue3Product = tSimilar[1]?.name;
                                img3 = tSimilar[1]?.img;
                                tProductId3 = tSimilar[1]?.productid;
                            }

                            tDSimilar.push({
                                specname: rspec.text,
                                value1: rspec.value,
                                value1product: tData?.name,
                                img1: tData?.img,
                                value2: tValue2,
                                value2product: tValue2Product,
                                img2: img2,
                                productid2: tProductId2,
                                value3: tValue3,
                                value3product: tValue3Product,
                                img3: img3,
                                productid3: tProductId3,
                            });
                        });
                    }

                    let tImages = [];
                    if (tData?.images) {
                        Object.keys(tData.images)?.map(key => {
                            tImages.push(tData.images[key].img);
                        });
                    }
                    tData.images = tImages;
                }
            });

            if (tData.categoryid) {
                await get(query(ref(getDatabase(firebaseApp), "product"), orderByChild("categoryid"), equalTo(tData.categoryid))).then(response => {
                    let resp = response.toJSON();
                    if (resp) {
                        Object.keys(resp)?.map((key, index) => {
                            if (resp[key]?.active && key != params.id) {
                                tDataCat.push({ id: key, ...resp[key] });
                            }
                        });
                    }
                });
            }

            setLoading(false);
        }

        setData(tData);
        setDataCat(tDataCat);
        setDProductType(tDProductType);
        setDOProductType(tDProductType);
        setDSimilar(tDSimilar);
        setDUseProduct(tDUseProduct);
    }

    useEffect(() => {
        getData();
    }, [params?.id]);

    const onBack = (pUrl) => {
        navigate(pUrl);
    }

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }

            if (field == "useproductid") {
                let tDProductType = dOProductType?.filter(r => r.productid == key);
                setDProductType(tDProductType);
                tData.price = 0;
                tData.producttypeid = null;
                tData.producttypename = null;
            }

            if (field == "producttypeid") {
                let tIndex = tData?.producttype?.map(r => r.id).indexOf(key);
                if (tIndex >= 0 && tData?.producttype[tIndex].price > 0) {
                    tData.price = parseFloat(tData?.producttype[tIndex].price);
                }
            }

            setData(tData);
        }
    }

    const onCart = async () => {

        if (data?.price > 0) {

            let tData = { ...data };
            let tCart = getCart();

            let tIndex = tCart?.map(r => r.id).indexOf(tData?.id);

            if (tIndex >= 0) {
                tCart[tIndex].qty = parseInt(tCart[tIndex].qty) + parseInt(tData.qty);
            } else {
                tCart.push({ ...tData });
            }

            setCart(tCart);
            localStorage.setItem("cart", JSON.stringify(tCart));

            tData.qty = 1;
            setData(tData);
            setCartPrev(true);
        } else if (data?.producttype?.length > 0) {
            message.warn(t("Барааны хэмжээ сонгоно уу!"));
        }
    }

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    return (
        <div className='mainPadding' style={{ paddingTop: 20, paddingBottom: 20 }}>
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => onBack("/products")}>{t("Бүтээгдэхүүн")}</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => onBack("/products?categoryid=" + data?.categoryid)}>{data?.categoryname}</Breadcrumb.Item>
                    <Breadcrumb.Item>{data?.name}</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                        <div>
                            <div className='mainProdImage' style={{ background: '#FFFFFF' }}>
                                {data.img ? (
                                    <img className='mainProdImage' style={{ border: '1px solid #E7E7E7' }} src={data.img} alt="" />
                                ) : (
                                    <img className='mainProdImage' style={{ border: '1px solid #E7E7E7' }} src={noImage} alt="" />
                                )}
                            </div>
                            <div style={{ marginTop: 24 }}>
                                {data?.images?.length > 0 ? (
                                    <ProductImages data={data.images} />
                                ) : null}
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                        <div>
                            <div style={{ fontFamily: 'Montserrat', fontWeight: 700, fontSize: 20, color: '#212121' }}>
                                {data.name}
                            </div>
                            <div style={{ marginTop: 19, fontWeight: 300, fontSize: 14, color: '#000000' }}>
                                {data.description}
                            </div>
                            {dProductType?.length > 0 && (
                                <div>
                                    <div style={{ marginTop: 19, maxWidth: 280 }}>
                                        <FieldDropdown placeholder="Хөрөө" loading={loading} onChanged={fieldOnChanged} keyfield='useproductid' keyfieldname="useproductname" defaultValue={data?.useproductid} data={dUseProduct} />
                                    </div>
                                    <div style={{ marginTop: 13, maxWidth: 280 }}>
                                        <FieldDropdown disable={data?.useproductid ? false : true} placeholder="Хэмжээ" loading={loading} onChanged={fieldOnChanged} keyfield='producttypeid' keyfieldname="producttypename" defaultValue={data?.producttypeid} data={dProductType} />
                                    </div>
                                </div>
                            )}
                            <div style={{ marginTop: 40, fontWeight: 400, fontSize: 12, color: '#767676' }}>
                                 {t("Төхөөрөмжийн үнэ")}
                            </div>
                            <div style={{ marginTop: 8 }}>
                                {data?.price > 0 ? (
                                    <NumberBase value={data.price} />
                                ) : (
                                    <div style={{ fontWeight: 600, fontSize: 32, color: '#212121' }}>
                                        -- ₮
                                    </div>
                                )}
                            </div>
                            <div style={{ marginTop: 40, display: 'flex' }}>
                                <ButtonAddReduce onChange={(pValue) => fieldOnChanged(pValue, "", "qty")} value={data.qty} />
                                <div style={{ marginLeft: 20 }}>
                                    <ButtonCart loading={loadingCart} onClick={onCart} />
                                </div>
                            </div>
                            <div style={{ marginTop: 40, fontFamily: 'Montserrat', fontWeight: 700, fontSize: 16, color: '#212121' }}>
                                {t("Үзүүлэлтүүд")}
                            </div>
                            <div style={{ marginTop: 16, maxWidth: 600 }}>
                                {data?.specifications?.map((r, index) => {
                                    return (
                                        <div key={index} style={{ marginTop: 8, display: 'flex', justifyContent: 'space-between', fontWeight: 500, fontSize: 14, color: '#000000' }}>
                                            <div>{r.text}</div>
                                            <div style={{ textAlign: 'right' }}>{r.value}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            {dSimilar?.length > 0 && (
                <div style={{ marginTop: 32 }}>
                    <div style={{ fontFamily: 'Montserrat', fontWeight: 700, fontSize: 20, color: '#212121' }}>
                     {t("Ижил төстэй барааны харьцуулалт")}
                    </div>
                    <div className='similarProduct' style={{ marginTop: 24 }}>
                        <List
                            locale={{
                                emptyText: t("Мэдээлэл байхгүй")
                            }}
                            style={{
                                border: '1px solid #E7E7E7'
                            }}
                            dataSource={dSimilar}
                            renderItem={(item, index) => (
                                <List.Item style={{ background: Number.isInteger((index + 1) / 2) ? '#FFFFFF' : '#F6F6F6' }}>
                                    <SimilarProduct index={index} total={dSimilar?.length} data={item} />
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            )}
            <div style={{ marginTop: 32, fontFamily: 'Montserrat', fontWeight: 700, fontSize: 20, color: '#212121' }}>
                {t("Төстэй бараа бүтээгдэхүүнүүд")}
            </div>
            <div style={{ marginTop: 24 }}>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 3,
                        xl: 4,
                        xxl: 4,
                    }}
                    locale={{
                        emptyText: t("Мэдээлэл байхгүй")
                    }}
                    dataSource={dataCat}
                    renderItem={item => (
                        <List.Item>
                            <Product data={item} />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
}

export default ProductDtl;