import React from 'react';

const Category = (props) => {
    return (
        <div onClick={props.onClick} style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 30, cursor: 'pointer' }}>
            <div style={{ fontWeight: 400, fontSize: 14, color: props.active ? '#FFA300' : '#212121' }}>{props.name}</div>
            <div style={{
                background: '#E0E0E0',
                width: 20,
                height: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 10,
                fontWeight: 400,
                fontSize: 14,
                color: props.active ? '#FFA300' : '#212121'
            }}>
                {props.count}
            </div>
        </div>
    );
}

export default Category;