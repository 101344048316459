// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAG3h_xfzQG-66HBWVYydExwaXEBjdQET0",
    authDomain: "taigiin-shand.firebaseapp.com",
    databaseURL: "https://taigiin-shand-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "taigiin-shand",
    storageBucket: "taigiin-shand.appspot.com",
    messagingSenderId: "60663653225",
    appId: "1:60663653225:web:c84dc1f1afc2cecdc7d3f8"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);