import React from 'react';
import { ReactComponent as Facebook } from '../../Svg/facebook.svg';
import { ReactComponent as LinkedIn } from '.././../Svg/linkedin.svg';
import moment from 'moment';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {

    const { t } = useTranslation();
    const styles = {
        container: {
            flex: 1,
            minHeight: 120
        },
        title: {
            fontWeight: 600,
            fontSize: 14,
            color: '#FFA300',
            marginBottom: 6,
            textAlign: 'center'
        },
        text: {
            fontWeight: 300,
            fontSize: 14,
            color: '#FFFFFF',
            textAlign: 'center'
        }
    }

    const onPressLink = (pUrl) => {
        window.open(pUrl, '_blank');
    }

    return (
        <div>
            <div className='mainPadding' style={{ minHeight: 190, background: '#233153' }}>
                <div style={{ textAlign: 'center', paddingTop: 24, fontFamily: 'Montserrat', fontWeight: 600, fontSize: 24, color: '#FFFFFF' }}>
                    {t("Холбоо барих")}
                </div>
                <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                        <div style={{ ...styles.container }}>
                            <div style={{ ...styles.title }}>
                                {t("Оффисын хаяг")}
                            </div>
                            <div style={{ ...styles.text }}>
                                {t("FooterOfficeAddress")}
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                        <div style={{ ...styles.container }}>
                            <div style={{ ...styles.title }}>
                                {t("Үйлдвэрийн хаяг")}
                            </div>
                            <div style={{ ...styles.text }}>
                                {t("FooterFactoryAddress")}
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                        <div style={{ ...styles.container }}>
                            <div style={{ ...styles.title }}>
                                {t("Холбоо барих")}
                            </div>
                            <div style={{ ...styles.text }}>
                                info@taigiinshand.mn
                                <br />
                                +976 8080-4050, +976 8899-0782
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                        <div style={{ ...styles.container }}>
                            <div style={{ ...styles.title }}>
                                {t("Биднийг дагаарай")}
                            </div>
                            <div style={{ ...styles.text }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 12 }}>
                                    <div onClick={() => onPressLink("https://www.facebook.com/taigiinshand")} style={{ cursor: 'pointer' }}>
                                        <Facebook />
                                    </div>
                                    <div onClick={() => onPressLink("https://www.linkedin.com/company/taigiin-shand")} style={{ marginLeft: 16, cursor: 'pointer' }}>
                                        <LinkedIn />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='mainPadding' style={{ height: 40, background: '#13234B', display: 'flex', alignItems: 'center' }}>
                <div style={{ fontWeight: 600, fontSize: 14, color: '#FFFFFF' }}>
                    All Rights Reserved @ Taigiin shand llc {moment().format("YYYY")}
                </div>
            </div>
        </div>
    );
}

export default Footer;