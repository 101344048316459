import { get, getDatabase, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { firebaseApp } from '../../API/firebaseApp';
import Category from '../../Components/Category/Category';
import Product from '../../Components/Product/Product';
import brand1 from '../../Images/brand1.png';
import brand2 from '../../Images/brand2.png';
import brand3 from '../../Images/brand3.png';
import { LoadScreen } from 'dw-components';
import { useSearchParams } from 'react-router-dom';
import { Col, Row, List } from 'antd';
import { useTranslation } from 'react-i18next';

const Products = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [activeCat, setActiveCat] = useState();
    const [category, setCategory] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation(); 

    const getData = async () => {
        setLoading(true);

        let tCategory = [];
        let tProduct = [];

        await get(ref(getDatabase(firebaseApp), "category")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    if (resp[key]?.active) {
                        tCategory.push({ id: key, ...resp[key] });
                    }
                });
            }
        });

        await get(ref(getDatabase(firebaseApp), "product")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    if (resp[key]?.active) {
                        tProduct.push({ id: key, ...resp[key] });
                    }
                });
            }
        });

        tCategory.sort((a, b) => {
            if (a.created < b.created) {
                return -1;
            }
            if (a.created > b.created) {
                return 1;
            }
            return 0;
        });

        tCategory?.map(r => {
            let tCatProduct = [];
            tProduct?.map((ri, index) => {
                if (ri.categoryid == r.id) {
                    tCatProduct.push(ri);
                }
            });
            r.product = tCatProduct;
        });

        if (tCategory?.length > 0) {

            if (searchParams.get("categoryid")) {
                let tIndex = tCategory?.map(r => r.id).indexOf(searchParams.get("categoryid"));
                if (tIndex >= 0) {
                    setActiveCat(tCategory[tIndex]);
                    setSearchParams({
                        categoryid: tCategory[tIndex].id
                    });
                    setSelectedProduct(tCategory[tIndex].product);
                }
            } else {
                setActiveCat(tCategory[0]);
                setSearchParams({
                    categoryid: tCategory[0].id
                });
                setSelectedProduct(tCategory[0].product);
            }
        }

        setCategory(tCategory);
        setProduct(tProduct);
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {

        let tSelectedProduct = [];
        if (searchParams.get("categoryid") && product?.length > 0) {
            product?.map(r => {
                if (r.categoryid == searchParams.get("categoryid")) {
                    tSelectedProduct.push(r);
                }
            });
        } else if (searchParams.get("brandid") && product?.length > 0) {
            product?.map(r => {
                if (r.brandid == searchParams.get("brandid")) {
                    tSelectedProduct.push(r);
                }
            });
        } else if (!searchParams.get("categoryid") && category?.length > 0) {
            setActiveCat(category[0]);
            tSelectedProduct = category[0].product;
        }
        setSelectedProduct(tSelectedProduct);

    }, [searchParams]);

    const onSelectCat = (pCat) => {
        setSearchParams({
            categoryid: pCat.id
        });
        setActiveCat(pCat);
    }


    const onSelectBrand = (pId, pName) => {
        setSearchParams({
            brandid: pId
        });
        setActiveCat({
            name: pName
        });
    }

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    return (
        <div className='mainPadding' style={{ marginTop: 40, marginBottom: 40 }}>
            <Row gutter={[32, 32]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }} xxl={{ span: 6 }}>
                    <div style={{ borderRight: '1px solid #E8E8E8' }}>
                        <div style={{ fontWeight: 500, fontSize: 20, color: '#000000' }}>
                            {t("Ангилал")} 
                        </div>
                        {category?.map(r => {
                            return (
                                <div key={r.id} style={{ marginTop: 16 }}>
                                    <Category onClick={() => onSelectCat(r)} name={r.name} count={r?.product?.length} active={r.id == activeCat?.id} />
                                </div>
                            );
                        })}
                        <div style={{ fontWeight: 500, fontSize: 20, color: '#000000', marginTop: 36 }}>
                            {t("Брэнд")}  
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <div onClick={() => onSelectBrand("1", "ТАЙГА")} style={{ cursor: 'pointer' }}>
                                <img src={brand1} alt='' style={{ height: 32 }} />
                            </div>
                            <div onClick={() => onSelectBrand("2", "АЛТАЙ")} style={{ marginTop: 25, cursor: 'pointer' }}>
                                <img src={brand2} alt='' style={{ height: 32 }} />
                            </div>
                            <div onClick={() => onSelectBrand("3", "BESTAR")} style={{ marginTop: 25, cursor: 'pointer' }}>
                                <img src={brand3} alt='' style={{ height: 32 }} />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 18 }} xl={{ span: 19 }} xxl={{ span: 18 }}>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontWeight: 500, fontSize: 20, color: '#000000' }}>
                                {activeCat?.name}
                            </div>
                            <div style={{ fontWeight: 400, fontSize: 14, color: '#000000' }}>
                                Нийт {selectedProduct?.length} бүтээгдэхүүн
                            </div>
                        </div>
                        <div style={{ marginTop: 8, fontWeight: 300, fontSize: 12, color: '#000000' }}>
                            {activeCat?.description}
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <List
                                grid={{
                                    gutter: 16,
                                    xs: 1,
                                    sm: 1,
                                    md: 1,
                                    lg: 2,
                                    xl: 3,
                                    xxl: 4,
                                }}
                                locale={{
                                    emptyText: t("Мэдээлэл байхгүй")
                                }}
                                dataSource={selectedProduct}
                                renderItem={item => (
                                    <List.Item>
                                        <Product data={item} />
                                    </List.Item>
                                )}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Products;