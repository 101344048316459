import React, { useEffect, useState } from 'react';
import { Route, Router, Routes, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Col, Menu, Row } from 'antd';
import MenuLabel from '../../Components/MenuLabel/MenuLabel';
import UserDtl from '../UserDtl/UserDtl';
import Orders from '../Orders/Orders';
import { checkUserAuth } from '../../Const/SharedFunc';
import { useTranslation } from 'react-i18next';

const UserInfo = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [userAuth, setUserAuth] = useState({});
    const { t } = useTranslation();

    const [currentMenu, setCurrentMenu] = useState("dtl");

    useEffect(() => {

        let tUserAuth = checkUserAuth();
        setUserAuth(tUserAuth ? tUserAuth : {});

        if (location?.pathname) {
            if (location.pathname.split("/").length > 2) {
                setCurrentMenu(location.pathname.split("/")[2]);
            }
        }

        if (!tUserAuth?.uid) {
            navigate("/login");
        }
    }, []);

    const onChangeMenu = (p_value) => {
        if (p_value.key == "exit") {
            localStorage.removeItem("userAuth");
            navigate("/login");
        } else {
            setCurrentMenu(p_value.key);
            navigate("/userinfo/" + p_value.key);
        }
    }

    if (userAuth?.uid) {
        return (
            <div className='mainPadding' style={{ paddingTop: 25, paddingBottom: 25 }}>
                <Row gutter={[32, 32]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
                        <div className='whiteLeftPanel'>
                            <div style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}>
                                <div style={{ padding: '32px 16px', fontWeight: 700, fontFamily: 'Montserrat' }}>
                                    {userAuth.phoneNumber ? userAuth.phoneNumber.substring(4) : ""}
                                </div>
                                <Menu onClick={onChangeMenu} defaultSelectedKeys={[currentMenu]} items={[{
                                    key: "dtl",
                                    label: (<MenuLabel black name="dtl" current={currentMenu} title={t("Хувийн мэдээлэл")} />)
                                }, {
                                    key: "orderinfo",
                                    label: (<MenuLabel black name="orderinfo" current={currentMenu} title={t("Миний захиалгууд")} />)
                                }, {
                                    key: "exit",
                                    label: (<MenuLabel black name="exit" current={currentMenu} title={t("Гарах")} />)
                                }]} />
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 16 }} xxl={{ span: 16 }}>
                        <div>
                            <Routes>
                                <Route path='/dtl' element={<UserDtl />} />
                                <Route path='/orderinfo' element={<Orders />} />
                            </Routes>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    } else {
        return null;
    }

}

export default UserInfo;