import { Number } from 'dw-components';
import React from 'react';

const CartChart = (props) => {
    return (
        <div style={{ border: '1px solid #E9E9E9', borderRadius: 4, padding: 12, display: 'flex', alignItems: 'center', height: '100%' }}>
            <div>
                {props?.icon}
            </div>
            <div style={{ marginLeft: 8, width: '100%' }}>
                <div style={{ fontWeight: 400, fontSize: 12 }}>
                    {props?.title}
                </div>
                <div style={{ fontWeight: 600, fontSize: 20 }}>
                    <Number value={props?.count} />
                </div>
            </div>
        </div>
    );
}

export default CartChart;