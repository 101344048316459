import React, { useEffect, useState } from 'react';
import Title from '../../Components/Title/Title';
import { Row, Col, message } from 'antd';
import FieldText from '../../Components/FieldText/FieldText';
import FieldLabel from '../../Components/FieldLabel/FieldLabel';
import FieldDropdown from '../../Components/FieldDropdown/FieldDropdown';
import ButtonBase from '../../Components/ButtonBase/ButtonBase';
import FieldDate from '../../Components/FieldDate/FieldDate';
import { get, getDatabase, push, ref, set, query, orderByChild, equalTo } from 'firebase/database';
import { firebaseApp } from '../../API/firebaseApp';
import moment from 'moment';
import { LoadSpin } from 'dw-components';
import { useTranslation } from 'react-i18next';

const UserDtl = (props) => {

    const [data, setData] = useState({});
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const { t } = useTranslation();

    const getInitData = async () => {
        let tData = { ...data };
        if (localStorage.getItem("userAuth")) {
            let tUserAuth = JSON.parse(localStorage.getItem("userAuth"));
            tData.phone = tUserAuth?.phoneNumber;
            tData.uid = tUserAuth.uid;
        }

        if (tData.uid) {
            setLoading(true);
            await get(query(ref(getDatabase(firebaseApp), "user"), orderByChild("uid"), equalTo(tData.uid))).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    Object.keys(resp)?.map(key => {
                        tData.id = key;
                        tData = resp[key];
                        return true;
                    });
                }
            }).catch(response => {
                setLoading(false);
            });
            setLoading(false);
        }

        setData(tData);
        setEdit(false);
    }

    useEffect(() => {
        getInitData();
    }, []);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }

            setData(tData);
            setEdit(true);
        }
    }

    const onSave = async () => {
        setLoadingSave(true);
        let tData = { ...data };
        if (tData.id) {
            await set(ref(getDatabase(firebaseApp), "user/" + tData.id), tData).then(response => {
                message.success(t("Амжилттай"));
            });
        } else {
            tData.created = moment().format("YYYY.MM.DD HH:mm:ss");
            await push(ref(getDatabase(firebaseApp), "user"), tData).then(response => {
                tData.id = response.key;
                message.success(t("Амжилттай"));
            });
        }
        setData(tData);
        setLoadingSave(false);
        setEdit(false);
    }

    return (
        <div>
            <Title value={t("Хувийн мэдээлэл")} />
            <Row style={{ marginTop: 24 }} gutter={[16, 16]}>
                <Col span={8}>
                    <FieldLabel value={t("Овог")} />
                    <FieldText loading={loading} onChanged={fieldOnChanged} keyfield='lastname' defaultValue={data.lastname} />
                </Col>
                <Col span={8}>
                    <FieldLabel value={t("Нэр")} />
                    <FieldText loading={loading} onChanged={fieldOnChanged} keyfield='firstname' defaultValue={data.firstname} />
                </Col>
                <Col span={8}>
                    <FieldLabel value={t("Хүйс")} />
                    <FieldDropdown loading={loading} onChanged={fieldOnChanged} keyfield='gender' keyfieldname="gendername" defaultValue={data.gender} data={[
                        {
                            key: "1",
                            text: t("Эр")
                        }, {
                            key: "2",
                            text: t("Эм")
                        }
                    ]} />
                </Col>
            </Row>
            <Row style={{ marginTop: 24 }} gutter={[16, 16]}>
                <Col span={8}>
                    <FieldLabel value={t("Төрсөн огноо")} />
                    <FieldDate loading={loading} onChanged={fieldOnChanged} keyfield='birthday' defaultValue={data.birthday} />
                </Col>
                <Col span={8}>
                    <FieldLabel value={t("Утасны дугаар")} />
                    <FieldText loading={loading} disable={true} onChanged={fieldOnChanged} keyfield='phone' defaultValue={data.phone} />
                </Col>
                <Col span={8}>
                    <FieldLabel value={t("И-мэйл хаяг")} />
                    <FieldText loading={loading} onChanged={fieldOnChanged} keyfield='email' defaultValue={data.email} />
                </Col>
            </Row>
            <Row style={{ marginTop: 24 }} gutter={[16, 16]}>
                <Col span={8}>
                    <FieldLabel value={t("Байгууллагын нэр")} />
                    <FieldText loading={loading} onChanged={fieldOnChanged} keyfield='companyname' defaultValue={data.companyname} />
                </Col>
            </Row>
            <div style={{ marginTop: 24, display: 'flex' }}>
                {loadingSave ? (
                    <LoadSpin />
                ) : (
                    edit && <ButtonBase onClick={onSave} text={t("Хадгалах")} />
                )}
            </div>
        </div>
    );
}

export default UserDtl;