import { Col, Divider, List, Row } from 'antd';
import { Number } from 'dw-components';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCart } from '../../Const/SharedFunc';
import { CartContext } from '../../Context/CartContext';
import ButtonAddReduce from '../ButtonAddReduce/ButtonAddReduce';
import ButtonBase from '../ButtonBase/ButtonBase';

const CartPrev = (props) => {

    const navigate = useNavigate();
    const { cart, setCartPrev } = useContext(CartContext);
    const substrcount = 28;

    return (
        <div style={{ width: 280 }}>
            <List
                itemLayout='horizontal'
                dataSource={cart}
                locale={{
                    emptyText: "Мэдээлэл байхгүй"
                }}
                renderItem={(item, index) => {
                    return (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <img style={{ width: 60, height: 60, border: '1px solid #E7E7E7' }} src={item.img} />
                                <div style={{ marginLeft: 16, width: '100%' }}>
                                    <div style={{ fontWeight: 400, fontSize: 14, color: '#212121' }}>
                                        {item?.name?.length > substrcount ? (item?.name?.substr(0, substrcount) + "...") : item.name}
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 8, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ fontWeight: 400, fontSize: 14, color: '#767676' }}>
                                            {item.qty} x {item.price}
                                        </div>
                                        <div style={{ marginLeft: 16, fontWeight: 500, fontSize: 14 }}>
                                            <Number value={parseFloat((parseFloat(item.price) * parseInt(item.qty)).toFixed(2))} symbol="₮" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </List.Item>
                    );
                }}
            />
            <Divider />
            <div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 16 }}>
                    <div style={{ fontWeight: 500, fontSize: 14, color: "#212121" }}>
                        Нийт
                    </div>
                    <div style={{ fontWeight: 500, fontSize: 16, color: "#212121" }}>
                        {cart?.length > 0 && (
                            <Number value={cart?.map(r => { return parseFloat((parseFloat(r.price) * parseInt(r.qty)).toFixed(2)) })?.reduce((a, b) => a + b, 0)} symbol="₮" />
                        )}
                    </div>
                </div>
                <ButtonBase onClick={() => { setCartPrev(false); navigate("/cart") }} text="Миний сагс" />
            </div>
        </div>
    );
}

export default CartPrev;