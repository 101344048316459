import React, { useState, useEffect } from 'react';
import { PageHeader, GridDefaultShimmer, ColEdit } from 'dw-components';
import CategoryNew from './New/CategoryNew';
import { get, ref, getDatabase } from 'firebase/database';
import { firebaseApp } from '../../../API/firebaseApp';

const Category = (props) => {

    const [data, setData] = useState([]);
    const [oData, setOData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState();
    const [showNew, setShowNew] = useState(false);

    const [columns, setColumns] = useState([
        {
            name: 'Категорийн нэр',
            fieldName: 'name',
            onRender: (item) => {
                return (
                    <ColEdit onClick={() => onEdit(item.id)}>{item.name}</ColEdit>
                );
            },
            minColSize: 'mid'
        }, {
            name: 'Үүсгэсэн огноо',
            fieldName: 'created',
            minColSize: 'mid'
        }, {
            name: 'Төлөв',
            fieldName: 'active',
            onRender: (item) => {
                if (item.active) {
                    return (
                        <div>Идэвхтэй</div>
                    );
                } else {
                    return (
                        <div>Идэвхгүй</div>
                    );
                }
            }
        }
    ]);

    const getData = async () => {
        let tData = [];

        await get(ref(getDatabase(firebaseApp), "category")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    tData.push({ id: key, ...resp[key] });
                });
            }
        });

        tData.sort((a, b) => {
            if (a.created > b.created) {
                return -1;
            }
            if (a.created < b.created) {
                return 1;
            }
            return 0;
        });

        setData(tData);
        setOData(tData);
    }

    useEffect(() => {
        getData();
    }, []);

    const onSearch = (p_data) => {
        setData(p_data);
    }

    const onEdit = (pId) => {
        setId(pId);
        setShowNew(true);
    }

    const onNew = () => {
        setId();
        setShowNew(true);
    }

    const onDismiss = () => {
        setId();
        setShowNew(false);
    }

    const onAfterSave = async () => {
        await getData();
    }

    return (
        <div>
            <PageHeader
                title="Категори"
                buttonTitle="Категори нэмэх"
                onButtonClick={onNew}
                dataCount={data?.length}
                columns={columns}
                data={oData}
                filteredData={data}
                onSearch={onSearch}
            />
            <GridDefaultShimmer
                data={data}
                columns={columns}
                loading={loading}
            />
            <CategoryNew
                isOpen={showNew}
                onDismiss={onDismiss}
                id={id}
                onAfterSave={onAfterSave}
            />
        </div>
    );
}

export default Category;