import React from 'react';
import ButtonDtl from '../ButtonDtl/ButtonDtl';

const SliderItem = (props) => {
    return (
        <div>
            <img style={{ width: '100%' }} src={props.img} alt="" />
        </div>
    );
}

export default SliderItem;