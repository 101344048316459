import React, { useState, useEffect } from 'react';
import { PageHeader, GridDefaultShimmer, ColEdit, Number } from 'dw-components';
import { get, ref, getDatabase } from 'firebase/database';
import { firebaseApp } from '../../../API/firebaseApp';
import { useNavigate } from 'react-router-dom';

const Order = (props) => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [oData, setOData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterValue, setFilterValue] = useState('active');
    const [filterData, setFilterData] = useState([{
        key: 'active',
        text: 'Идэвхтэй'
    }, {
        key: 'inactive',
        text: 'Идэвхгүй'
    }]);

    const [columns, setColumns] = useState([
        {
            name: 'Захиалгын №',
            fieldName: 'refno',
            onRender: (item) => {
                return (
                    <ColEdit onClick={() => onEdit(item.id)}>{item.refno}</ColEdit>
                );
            },
            minColSize: 'small'
        }, {
            name: 'Захиалсан огноо',
            fieldName: 'created',
            minColSize: 'small'
        }, {
            name: 'Төлөв',
            fieldName: 'statusname',
            minColSize: 'mid',
            onRender: (item) => {
                if (item.status == 0) {
                    return (
                        <div style={{ display: 'flex' }}>
                            <div style={{ background: '#E5F7B2', padding: '0px 16px', borderRadius: 10, color: '#D98507' }}>
                                {item.statusname}
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div style={{ display: 'flex' }}>
                            <div style={{ background: '#E1EFFE', padding: '0px 16px', borderRadius: 10, color: '#6E83F8' }}>
                                {item.statusname}
                            </div>
                        </div>
                    );
                }
            }
        }, {
            name: 'Хэрэглэгч',
            fieldName: 'user',
            minColSize: 'small',
            onRender: (item) => {
                return (
                    <div>{item.phone}</div>
                );
            }
        }, {
            name: 'Хүргэлт',
            fieldName: 'delivery',
            minColSize: 'small',
            onRender: (item) => {
                if (item.delivery > 0) {
                    return (
                        <div>Унаанд өгч явуулах</div>
                    );
                } else {
                    return (
                        <div>Салбараас очиж авах</div>
                    );
                }
            }
        }, {
            name: 'Ашиглах газар',
            fieldName: 'district',
            minColSize: 'mid'
        }, {
            name: 'Тоо, ширхэг',
            fieldName: 'qty',
            minColSize: 'small',
            onRender: (item) => {
                let vQty = 0;
                if (item?.item?.length > 0) {
                    item.item.map(r => {
                        if (!isNaN(r.qty)) {
                            vQty += parseInt(r.qty);
                        }
                    });
                }

                return (
                    <Number value={vQty} />
                );
            }
        }, {
            name: 'Нийт дүн',
            fieldName: 'price',
            onRender: (item) => {
                let vPrice = 0;
                if (item?.item?.length > 0) {
                    item.item.map(r => {
                        if (!isNaN(r.qty) && !isNaN(r.price)) {
                            vPrice += parseInt(r.qty) * parseFloat(r.price);
                        }
                    });
                }

                return (
                    <Number value={vPrice} csymbol="₮" />
                );
            }
        }
    ]);

    const getData = async () => {
        let tData = [];

        setLoading(true);
        await get(ref(getDatabase(firebaseApp), "order")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {

                    if ((filterValue == "active" ? [0, 1] : [2]).includes(parseInt(resp[key].status))) {
                        let tItem = [];
                        if (resp[key].item) {
                            Object.keys(resp[key].item)?.map(itemkey => {
                                tItem.push(resp[key].item[itemkey]);
                            });
                        }

                        tData.push({ id: key, ...resp[key], item: tItem });
                    }

                });
            }
        }).catch(response => {
            setLoading(true);
        });
        setLoading(false);

        tData.sort((a, b) => {
            if (a.created > b.created) {
                return -1;
            }
            if (a.created < b.created) {
                return 1;
            }
            return 0;
        });

        setData(tData);
        setOData(tData);
    }

    useEffect(() => {
        getData();
    }, [filterValue]);

    const onSearch = (p_data) => {
        setData(p_data);
    }

    const onEdit = (pId) => {
        navigate("/admin/order/" + pId);
    }

    const onChangeFilter = (p_key, p_name) => {
        setFilterValue(p_key);
    }

    return (
        <div>
            <PageHeader
                showLeftFilter
                changeFilter={onChangeFilter}
                filterValue={filterValue}
                filterData={filterData}
                dataCount={data?.length}
                columns={columns}
                data={oData}
                filteredData={data}
                onSearch={onSearch}
            />
            <GridDefaultShimmer
                data={data}
                columns={columns}
                loading={loading}
            />
        </div>
    );
}

export default Order;