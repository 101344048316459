import React from 'react';
import NumberFormat from 'react-number-format';

const NumberBase = (props) => {
    return (
        <div style={{ fontWeight: 600, fontSize: 32, color: '#212121' }}>
            <NumberFormat value={props.value} displayType={'text'} thousandSeparator={true} suffix={' ₮'} />
        </div>
    );
}

export default NumberBase;