import React, { useState, useEffect, useContext } from 'react';
import { get, getDatabase, ref, query, orderByChild, equalTo, set, push } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { firebaseApp } from '../../API/firebaseApp';
import Title from '../../Components/Title/Title';
import { addAddress, checkUserAuth, delCart, getAddress, getCart, updateCart } from '../../Const/SharedFunc';
import TitleMid from '../../Components/TitleMid/TitleMid';
import { FieldNumberFormat, LoadSpin, Number } from 'dw-components';
import FieldDropdown from '../../Components/FieldDropdown/FieldDropdown';
import FieldText from '../../Components/FieldText/FieldText';
import { Radio, Space, List, Row, Col, Divider, message } from 'antd';
import ButtonBase from '../../Components/ButtonBase/ButtonBase';
import { ReactComponent as ShopIcon } from '../../Svg/shop.svg';
import ListHeader from '../../Components/ListHeader/ListHeader';
import ButtonAddReduce from '../../Components/ButtonAddReduce/ButtonAddReduce';
import { ReactComponent as DeleteIcon } from '../../Svg/delete.svg';
import { CartContext } from '../../Context/CartContext';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Cart = (props) => {

    const navigate = useNavigate();
    const [initData] = useState({
        delivery: 0,
        totalprice: 0
    });
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingOrder, setLoadingOrder] = useState(false);
    const { cart, setCart, setCartPrev } = useContext(CartContext);
    const { t } = useTranslation();

    const getData = async () => {
        let tData = { ...initData };

        let tAddress = getAddress();
        if (tAddress) {
            if (tAddress?.delivery) {
                tData.delivery = tAddress?.delivery;
            }
            if (tAddress?.district) {
                tData.district = tAddress?.district;
            }
            if (tAddress?.region) {
                tData.region = tAddress?.region;
            }
            if (tAddress?.regionname) {
                tData.regionname = tAddress?.regionname;
            }
        }

        tData.item = getCart();

        let tTotalItems = tData.item?.map(r => parseInt(r.qty) * parseFloat(r.price));
        tData.totalprice = parseFloat(parseFloat(tTotalItems.reduce((a, b) => a + b, 0)).toFixed(2));

        setData(tData);
        setCartPrev(false);
    }

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setData(tData);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const onDel = async (pItemId) => {
        if (pItemId) {
            delCart(pItemId);
            await getData();
            await refreshCart();
        }
    }

    const onDelAll = async () => {
        if (data?.item?.length > 0) {
            localStorage.removeItem("cart");
            await getData();
            await refreshCart();
        }
    }

    const refreshCart = async () => {
        let tCart = getCart();
        setCart(tCart);
    }

    const onChangeQty = async (pItemid, pQty) => {
        updateCart(pItemid, pQty);
        await refreshCart();
        await getData();
    }

    const onOrder = async () => {

        let tData = { ...data };

        const tUserAuth = checkUserAuth();

        if (tUserAuth?.uid) {
            if (data?.item?.length > 0) {
                if (data.region && data.district) {

                    addAddress({
                        delivery: tData?.delivery,
                        district: tData?.district,
                        region: tData?.region,
                        regionname: tData?.regionname
                    });

                    let success = false;
                    let tOrderId = null;
                    setLoadingOrder(true);
                    let tUserAuth = checkUserAuth();

                    tData.created = moment().format("YYYY.MM.DD HH:mm:ss");
                    tData.uid = tUserAuth.uid;
                    tData.refno = moment().unix();
                    tData.status = 0;
                    tData.statusname = t("Төлбөр хүлээгдэж буй");
                    tData.phone = tUserAuth.phoneNumber;

                    await push(ref(getDatabase(firebaseApp), "order"), tData).then(response => {
                        success = true;
                        tOrderId = response.key;
                    }).catch(respnose => {
                        setLoadingOrder(false);
                    });
                    await onDelAll();
                    await refreshCart();
                    setLoadingOrder(false);

                    if (success) {
                        navigate("/userinfo/orderinfo?orderid=" + tOrderId + "&payment=true");
                    }
                } else {
                    if (!data.region) {
                        message.warn(t("Аймаг, нийслэл сонгох талбарын утга хоосон байна."));
                    } else if (!data.district) {
                        message.warn(t("Сум, дүүрэг талбарын утга хоосон байна."));
                    }
                }

            } else {
                message.warn(t("Захиалга үүсгэх бараа байхгүй байна."));
            }
        } else {
            addAddress({
                delivery: tData?.delivery,
                district: tData?.district,
                region: tData?.region,
                regionname: tData?.regionname
            });
            navigate("/login?callback=cart");
        }
    }

    return (
        <div className='mainPadding' style={{ paddingTop: 36, paddingBottom: 36 }}>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 17 }} xxl={{ span: 16 }}>
                    <div>
                        <Title value={t("Миний сагс")} />
                        <div style={{ marginTop: 45 }}>
                            <Row gutter={[16, 16]} style={{ width: '100%' }}>
                                <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 1 }} lg={{ span: 1 }} xl={{ span: 1 }} xxl={{ span: 1 }}></Col>
                                <Col xs={{ span: 6 }} sm={{ span: 4 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} xxl={{ span: 3 }}></Col>
                                <Col xs={{ span: 16 }} sm={{ span: 12 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 9 }} xxl={{ span: 10 }}>
                                    <ListHeader value={t("Бүтээгдэхүүн")} />
                                </Col>
                                <Col xs={{ span: 0 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 3 }} xl={{ span: 4 }} xxl={{ span: 3 }}>
                                    <ListHeader value={t("Үнэ")} />
                                </Col>
                                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 4 }} lg={{ span: 3 }} xl={{ span: 3 }} xxl={{ span: 3 }}>
                                    <div style={{ textAlign: 'right' }}>
                                        <ListHeader value={t("Тоо, ширхэг")} />
                                    </div>
                                </Col>
                                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 4 }} xl={{ span: 4 }} xxl={{ span: 4 }}>
                                    <div style={{ textAlign: 'right' }}>
                                        <ListHeader value={t("Нийт")} />
                                    </div>
                                </Col>
                            </Row>
                            <Divider />
                            <List
                                itemLayout='horizontal'
                                dataSource={data?.item}
                                locale={{
                                    emptyText: t("Мэдээлэл байхгүй")
                                }}
                                renderItem={(item, index) => {
                                    return (
                                        <List.Item>
                                            <Row gutter={[16, 16]} align='middle' style={{ width: '100%', marginTop: index == 0 ? 0 : 16, marginBottom: (index + 1 == data?.item?.length) ? 0 : 16 }}>
                                                <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 1 }} lg={{ span: 1 }} xl={{ span: 1 }} xxl={{ span: 1 }}>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => onDel(item.id)}>
                                                        <DeleteIcon />
                                                    </div>
                                                </Col>
                                                <Col xs={{ span: 6 }} sm={{ span: 4 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} xxl={{ span: 3 }}>
                                                    <img style={{ width: 60, height: 60, border: '1px solid #E7E7E7' }} src={item.img} />
                                                </Col>
                                                <Col xs={{ span: 16 }} sm={{ span: 12 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 9 }} xxl={{ span: 10 }}>
                                                    <div style={{ fontWeight: 400, fontSize: 14, color: '#212121' }}>
                                                        {item.name} {item.producttypename && ("/" + item.producttypename + "/")}
                                                    </div>
                                                </Col>
                                                <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 3 }} xl={{ span: 4 }} xxl={{ span: 3 }}>
                                                    <div style={{ fontWeight: 400, fontSize: 14, color: '#212121' }}>
                                                        <Number value={item.price} symbol="₮" />
                                                    </div>
                                                </Col>

                                                <Col xs={{ span: 8 }} sm={{ span: 6, offset: 12 }} md={{ span: 6, offset: 0 }} lg={{ span: 3 }} xl={{ span: 3 }} xxl={{ span: 3 }}>
                                                    <div style={{ marginTop: -8 }}>
                                                        <ButtonAddReduce onChange={(pValue) => onChangeQty(item.id, pValue)} min value={item.qty} />
                                                    </div>
                                                </Col>
                                                <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 6, offset: 16 }} lg={{ span: 4, offset: 0 }} xl={{ span: 4, offset: 0 }} xxl={{ span: 4, offset: 0 }}>
                                                    <div style={{ fontWeight: 600, fontSize: 14, color: '#212121' }}>
                                                        <Number value={parseFloat((parseFloat(item.price) * parseInt(item.qty)).toFixed(2))} symbol="₮" />
                                                    </div>
                                                </Col>

                                            </Row>
                                        </List.Item>
                                    );
                                }}
                            />
                            <Divider />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ButtonBase onClick={onDelAll} color="#212121" text={t("Сагс хоослох")} />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 7 }} xxl={{ span: 8 }}>
                    <div style={{ background: '#FFFFFF', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', padding: 16 }}>
                        <Title value={t("Захиалгын мэдээлэл")} />
                        <div style={{
                            marginTop: 24,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px dashed #D3D3D3',
                            paddingBottom: 16
                        }}>
                            <TitleMid value={t("Бүтээгдэхүүний дүн")} />
                            <div style={{ fontWeight: 500, fontSize: 16, color: '#212121' }}>
                                <Number value={data?.totalprice} symbol="₮" />
                            </div>
                        </div>
                        <div style={{ marginTop: 16 }}>
                            <TitleMid value={t("Бүтээгдэхүүн ашиглах газар")} />
                        </div>
                        <div style={{ borderBottom: '1px dashed #D3D3D3', paddingBottom: 16 }}>
                            <div style={{ marginTop: 16 }}>
                                <FieldDropdown placeholder={t("Аймаг, нийслэл сонгох")} loading={loading} onChanged={fieldOnChanged} keyfield='region' keyfieldname="regionname" defaultValue={data.region} data={[
                                    {
                                        key: "22",
                                        text: "Улаанбаатар"
                                    }, {
                                        key: "1",
                                        text: "Архангай"
                                    }, {
                                        key: "2",
                                        text: "Баян-Өлгий"
                                    }, {
                                        key: "3",
                                        text: "Баянхонгор"
                                    }, {
                                        key: "4",
                                        text: "Булган"
                                    }, {
                                        key: "5",
                                        text: "Говь-Алтай"
                                    }, {
                                        key: "6",
                                        text: "Говьсүмбэр"
                                    }, {
                                        key: "7",
                                        text: "Дархан-Уул"
                                    }, {
                                        key: "8",
                                        text: "Дорноговь"
                                    }, {
                                        key: "9",
                                        text: "Дорнод"
                                    }, {
                                        key: "10",
                                        text: "Дундговь"
                                    }, {
                                        key: "11",
                                        text: "Завхан"
                                    }, {
                                        key: "12",
                                        text: "Орхон"
                                    }, {
                                        key: "13",
                                        text: "Өвөрхангай"
                                    }, {
                                        key: "14",
                                        text: "Өмнөговь"
                                    }, {
                                        key: "15",
                                        text: "Сүхбаатар"
                                    }, {
                                        key: "16",
                                        text: "Сэлэнгэ"
                                    }, {
                                        key: "17",
                                        text: "Төв"
                                    }, {
                                        key: "18",
                                        text: "Увс"
                                    }, {
                                        key: "19",
                                        text: "Ховд"
                                    }, {
                                        key: "20",
                                        text: "Хөвсгөл"
                                    }, {
                                        key: "21",
                                        text: "Хэнтий"
                                    }
                                ]} />
                            </div>
                            <div style={{ marginTop: 16 }}>
                                <FieldText placeholder={t("Сум, дүүрэг, хаяг")} loading={loading} onChanged={fieldOnChanged} keyfield='district' defaultValue={data.district} />
                            </div>
                        </div>
                        <div style={{ marginTop: 16 }}>
                            <Radio.Group onChange={(pValue) => fieldOnChanged(pValue.target.value, "", "delivery")} value={data?.delivery}>
                                <Space direction="vertical">
                                    <Radio value={0}>{t("Салбараас очиж авах")}: 0₮</Radio>
                                    <Radio value={10000}>{t("Унаанд өгч явуулах")}: 10,000₮</Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                        <div style={{
                            marginTop: 32,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <TitleMid value={t("Нийт дүн")} />
                            <div style={{ fontWeight: 500, fontSize: 20, color: '#212121' }}>
                                <Number value={data?.totalprice + data?.delivery} symbol="₮" />
                            </div>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            {loadingOrder ? (
                                <LoadSpin />
                            ) : (
                                <ButtonBase onClick={onOrder} icon={<ShopIcon />} text={t("Худалдан авах")} />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Cart;