import React from 'react';

const Employee = (props) => {
    return (
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <div style={{ marginLeft: 8 }}>
                <div style={{ fontWeight: 600, fontSize: 16, color: '#000000' }}>
                    {props.data?.name}
                </div>
                <div style={{ fontWeight: 400, fontSize: 14, color: '#000000' }}>
                    {props.data?.description}
                </div>
            </div>
        </div>
    );
}

export default Employee;