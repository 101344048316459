import React, { useEffect, useState } from 'react';
import { Row, Col, List, Space } from 'antd';
import NewsList from '../../Components/NewsList/NewsList';
import { checkUserAuth } from '../../Const/SharedFunc';
import { LoadScreen } from 'dw-components';
import { get, getDatabase, query, ref } from 'firebase/database';
import { firebaseApp } from '../../API/firebaseApp';
import { useTranslation } from 'react-i18next';

const News = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const getData = async () => {

        let tData = [];

        setLoading(true);
        await get(ref(getDatabase(firebaseApp), "news")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    if (resp[key].active) {
                        tData.push({ id: key, ...resp[key] });
                    }
                });
            }
        }).catch(response => {
            setLoading(false);
        });
        setLoading(false);

        tData?.sort((a, b) => {
            if (a.created > b.created) {
                return -1;
            }
            if (a.created < b.created) {
                return 1;
            }
            return 0;
        });
        setData(tData);
    }

    useEffect(() => {
        getData();
    }, []);

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    return (
        <div className='mainPadding' style={{ paddingTop: 38, paddingBottom: 38 }}>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                    <div style={{ fontFamily: 'Montserrat', fontWeight: 700, fontSize: 20, color: '#000000' }}>{t("Зөвлөгөө, мэдээлэл")}</div>
                    <div style={{ marginTop: 12, fontWeight: 400, fontSize: 14, color: '#212121', textAlign: 'justify' }}>
                    {t("Тайгын шанд компани нь нийгмийн хариуцлагынхаа хүрээнд нутгийн тэмцээн уралдааныг ивээн тэтгэх, байгаль орчинд ээлтэй үйл ажиллагаануудыг явуулж ирсэн.")} 
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 18 }}>
                    <List
                        itemLayout='horizontal'
                        dataSource={data}
                        locale={{
                            emptyText: t("Мэдээлэл байхгүй")
                        }}
                        renderItem={(item, indedx) => {
                            return (
                                <List.Item>
                                    <NewsList data={item} />
                                </List.Item>
                            )
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default News;