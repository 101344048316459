import { Breadcrumb, Col, Divider, List, message, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import ListHeader from '../../../Components/ListHeader/ListHeader';
import TitleGray from '../../../Components/TitleGray/TitleGray';
import TitleMid from '../../../Components/TitleMid/TitleMid';
import Title from '../../../Components/Title/Title';
import { ButtonDefault, FieldDropdown, LoadScreen, LoadSpin, Number } from 'dw-components';
import { Link, useParams } from 'react-router-dom';
import { get, getDatabase, ref, set, update } from 'firebase/database';
import { firebaseApp } from '../../../API/firebaseApp';

const OrderDtl = (props) => {

    const params = useParams();
    const [dataOrder, setDataOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const getData = async () => {
        let tDataOrder = {};

        if (params?.id) {
            setLoading(true);
            await get(ref(getDatabase(firebaseApp), "order/" + params.id)).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    let tItem = [];
                    if (resp.item) {
                        Object.keys(resp.item)?.map(itemkey => {
                            tItem.push(resp.item[itemkey]);
                        });
                    }
                    resp.item = tItem;
                    tDataOrder = resp;
                }
            });
            setLoading(false);
        }

        setEdit(false);
        setDataOrder(tDataOrder);
    }

    useEffect(() => {
        getData();
    }, [params?.id]);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...dataOrder };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setDataOrder(tData);
            setEdit(true);
        }
    }

    const onSave = async () => {

        if (params?.id) {
            setLoadingSave(true);
            await update(ref(getDatabase(firebaseApp), "order/" + params.id), {
                status: dataOrder.status,
                statusname: dataOrder.statusname
            }).then(response => {
                message.success("Амжилттай.");
            });
            setLoadingSave(false);
        }

        setEdit(false);
    }

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    return (
        <div style={{ background: '#FFFFFF', border: '1px solid #DADADA', padding: 16 }}>

            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/admin/order">Захиалгын жагсаалт</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Захиалгын дэлгэрэнгүй
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
                <div>
                    <Title value={"Захиалгын дугаар | " + dataOrder?.refno} />
                </div>
                <div>
                    <FieldDropdown loading={loading} title="" onChanged={fieldOnChanged} keyfield='status' keyfieldname='statusname' defaultValue={dataOrder.status} data={[{
                        key: 0,
                        text: "Төлбөр хүлээгдэж буй"
                    }, {
                        key: 1,
                        text: "Төлбөр баталгаажсан"
                    }, {
                        key: 2,
                        text: "Захиалга хүлээлгэн өгсөн"
                    }, {
                        key: 3,
                        text: "НӨАТ-ын баримт илгээсэн"
                    }, {
                        key: 4,
                        text: "Цуцлагдсан"
                    }]} />
                </div>
            </div>
            <div style={{ background: '#F9F9F9', marginTop: 16, marginBottom: 16, padding: 16 }}>
                <TitleMid value="Дэлгэрэнгүй мэдээлэл" />
                <div style={{ marginTop: 16 }}>
                    <Row>
                        <Col span={6}>
                            <TitleGray value="Бүтээгдэхүүн ашиглах газар:" />
                            <div style={{ marginTop: 4 }}>
                                <TitleMid value={dataOrder?.regionname} />
                            </div>
                        </Col>
                        <Col span={6}>
                            <TitleGray value="Хүргэлт:" />
                            <div style={{ marginTop: 4 }}>
                                <TitleMid value={dataOrder?.delivery > 0 ? "Унаанд өгч явуулах" : "Салбараас очиж авах"} />
                            </div>
                        </Col>
                        <Col span={6}>
                            <TitleGray value="Утас:" />
                            <div style={{ marginTop: 4 }}>
                                <TitleMid value={dataOrder?.phone} />
                            </div>
                        </Col>
                        <Col span={6}>
                            <TitleGray value="И-мэйл хаяг:" />
                            <div style={{ marginTop: 4 }}>
                                <TitleMid value={dataOrder?.email} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div style={{ marginTop: 45, marginRight: 24 }}>
                <Row style={{ width: '100%' }}>
                    <Col span={3}></Col>
                    <Col span={10}>
                        <ListHeader value="Бүтээгдэхүүн" />
                    </Col>
                    <Col span={3}>
                        <ListHeader value="Үнэ" />
                    </Col>
                    <Col span={3}>
                        <div style={{ textAlign: 'right' }}>
                            <ListHeader value="Тоо, ширхэг" />
                        </div>
                    </Col>
                    <Col span={4}>
                        <div style={{ textAlign: 'right' }}>
                            <ListHeader value="Нийт" />
                        </div>
                    </Col>
                </Row>
                <Divider />
                <List
                    itemLayout='horizontal'
                    dataSource={dataOrder?.item}
                    locale={{
                        emptyText: "Мэдээлэл байхгүй"
                    }}
                    renderItem={(item, index) => {
                        return (
                            <List.Item>
                                <Row align='middle' style={{ width: '100%', marginTop: index == 0 ? 0 : 16, marginBottom: (index + 1 == dataOrder?.item?.length) ? 0 : 16 }}>
                                    <Col span={3}>
                                        <img style={{ width: 80, height: 80, border: '1px solid #E7E7E7' }} src={item.img} />
                                    </Col>
                                    <Col span={10}>
                                        <div style={{ fontWeight: 400, fontSize: 14, color: '#212121' }}>
                                            {item.name} {item.producttypename && ("/" + item.producttypename + "/")}
                                        </div>
                                    </Col>
                                    <Col span={3}>
                                        <div style={{ fontWeight: 400, fontSize: 14, color: '#212121' }}>
                                            <Number value={item.price} symbol="₮" />
                                        </div>
                                    </Col>
                                    <Col span={3}>
                                        <div style={{ fontWeight: 600, fontSize: 14, color: '#212121' }}>
                                            <Number value={item.qty} />
                                        </div>
                                    </Col>
                                    <Col span={4}>
                                        <div style={{ fontWeight: 600, fontSize: 14, color: '#212121' }}>
                                            <Number value={parseFloat((parseFloat(item.price) * parseInt(item.qty)).toFixed(2))} symbol="₮" />
                                        </div>
                                    </Col>
                                </Row>
                            </List.Item>
                        );
                    }}
                />
                <Divider />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Space>
                    <TitleMid value="Нийт дүн" />
                    <Title value={<Number value={dataOrder?.totalprice} symbol="₮" />} />
                </Space>
            </div>
            <div style={{ marginTop: 16, display: 'flex' }}>
                {edit && (loadingSave ? (<LoadSpin />) : (<ButtonDefault text="Хадгалах" onClick={onSave} />))}
            </div>
        </div>
    );
}

export default OrderDtl;