import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    mn: {
        translation: {
            "aboutUsIntroText":"Тайгын шанд ХХК нь мод  боловсруулах үйлдвэрлэлийн тоног төхөөрөмж, дагалдах хэрэгслэлийн худалдаа,  туузан хөрөөний ирний үйлдвэрлэл, үйлчилгээг хэрэглэгчиддээ үзүүлдэг.",
            "FooterOfficeAddress": "Монгол улс, Улаанбаатар хот, Сүхбаатар дүүрэг, 10-р хороо, Эмон төв 404 тоот (100 айл, Хэвлэлийн хүрээлэнгийн баруун талд)",
            "FooterFactoryAddress": " Монгол улс, Улаанбаатар хот, Сонгинохайрхан дүүрэг, 22-р хороо, Хилчин-18 гудамж, 7 тоот"
        }
    },
    en: {
        translation: {
            "Нүүр хуудас": "Home",
            "Бидний тухай": "About Us",
            "Түүхэн замнал": "History", 
            "Бүтээгдэхүүн": "Products",
            "Зөвлөгөө, мэдээлэл": "Advice and Information",
            "aboutUsIntroText":"Taigiin Shand LLC provides its customers with the trade of woodworking equipment and accessories, the production of band saw blades, and services.",
            "ЗОРИЛГО": "MISSION",
            "АЛСЫН ХАРАА": "VISION",
            "ҮНЭТ ЗҮЙЛ": "VALUE",
            "Манай баг": "Our team",
            "Удирдлагын баг": "Management team",
            "Борлуулалтын баг": "Sales team",
            "Гүйцэтгэх захирал": "Executive Director",
            "Ерөнхий менежер": "General manager",
            "Үйлдвэрийн дарга": "Head of Factory",
            "Маркетингийн менежер": "Marketing Manager",
            "Гадаад харилцааны менежер": "Foreign Affair Manager",
            "Худалдааны зөвлөх": "Salesman",
            "Д.Болд": "Bold. D",
            "Д.Болормаа": "Bolormaa. D",
            "Л.Нармандах": "Narmandakh. L" ,
            "Н.Саруул":" Saruul. N",
            "Э.Өсөхбаяр":"Usukhbayar. E",
            "Л.Шүрэнцэцэг": "Shurentsetseg. L",
            "Б.Ангар": "Angar. B",
            "Холбоо барих": "Contact info",
            "Оффисын хаяг": "Office address",
            "FooterOfficeAddress":"Mongolia, Ulaanbaatar City, Sukhbaatar District, 10th District, Emon Center No. 404",
            "Үйлдвэрийн хаяг": "Factory address",
            "FooterFactoryAddress": "Mongolia, Ulaanbaatar city, Songinohairkhan district, 22nd district Khilchin-18 street, No. 7",
            "Биднийг дагаарай": "Follow us",
            "Ангилал": "Category",
            "Брэнд": "Brand",
            "НЭВТРЭХ": "LOG IN",
            "Нэвтрэх": "Log In",
            "Утасны дугаар": "Phone number",
            "Утасны дугаар оруулна уу!": "Please enter phone number!",
            "SMS илгээхэд алдаа гарлаа": "Error occurred to send SMS",
            "Баталгаажуулах код илгээлээ": "Verification code sent",
            "Баталгаажуулах код илгээгээгүй байна": "No verification code sent",
            "Таны оруулсан код буруу байна": "Wrong code",
            "Амжилттай":"Success",
            "Таны утсанд баталгаажуулах код илгээсэн": "Verification code is sent to your device",
            "Буцах": "Back",
            "Хувийн мэдээлэл": "Personal info",
            "Овог": "Lastname",
            "Нэр" : "Firstname",
            "Хүйс": "Gender",
            "Төрсөн огноо": "Birth date",
            "И-мэйл хаяг": "Email",
            "Байгууллагын нэр": "Company name",
            "Эр": "Male",
            "Эм" : "Female",
            "Хадгалах" : "Save",
            "Миний захиалгууд": "My orders",
            "Гарах": "Log Out",
            "Төхөөрөмжийн үнэ": "Price",
            "Үзүүлэлтүүд": "Specifications",
            "Ижил төстэй барааны харьцуулалт": "Comparison of similar products",
            "Төстэй бараа бүтээгдэхүүнүүд": "Similar products",
            "Тайгын шанд компани нь нийгмийн хариуцлагынхаа хүрээнд нутгийн тэмцээн уралдааныг ивээн тэтгэх, байгаль орчинд ээлтэй үйл ажиллагаануудыг явуулж ирсэн.": "As part of its social responsibility, Taig Shand Company has been sponsoring local competitions and carrying out environmentally friendly activities.",
            "Тоо, ширхэг": "Quantity",
            "Миний сагс": "My cart",
            "Үнэ": "Price",
            "Нийт": "Total",
            "Бүтээгдэхүүний дүн": "Product price",
            "Захиалгын мэдээлэл": "Order information",
            "Бүтээгдэхүүн ашиглах газар" : "Place of use of the product",
            "Аймаг, нийслэл сонгох" : "Choose province or capital",
            "Сум, дүүрэг, хаяг": "District, address",
            "Нийт дүн": "Total amount",
            "Худалдан авах":"Purchase",
            "Салбараас очиж авах":"Pick up from the branch",
            "Унаанд өгч явуулах" : "Shipping",
            "Сагс хоослох": "Empty cart",
            "Мэдээлэл байхгүй": "No information",
            "Захиалга үүсгэх бараа байхгүй байна." : "No item in your cart",
            "Аймаг, нийслэл сонгох талбарын утга хоосон байна.": "",
            "Аймаг, нийслэл сонгох талбарын утга хоосон байна.": "Please choose province or capital",
            "Сум, дүүрэг талбарын утга хоосон байна.": "Please fill district, address",
            "Төлбөр хүлээгдэж буй": "Waiting for the payment",
            "Захиалгын дугаар": "Order number",
            "Захиалга бүртгэсэн":"Created date",
            "Таны захиалга амжилттай бүртгэгдлээ.": "Your order has been successfully registered",
            "Төлбөр төлөгдсөний дараа таны захиалга баталгаажихыг анхаарна уу.": "Please note that your order will be confirmed after payment",
            "Дансаар төлөх": "Account information to receive the transfer",
            "Дансны дугаар:": "Account number",
            "5675562987 /ХААН/": "5675562987 /Khanbank/",
            "413003681 /ХХБ/":"413003681 /TDB/",
            "Хүлээн авагч:":"Receiver:",
            "Тайгын шанд ХХК": "Taigiin shand LLC",
            "Захиалгын дүн:": "Amount:",
            "Гүйлгээний утга:" : "Description:",
            "Дэлгэрэнгүй мэдээлэл": "Detailed information",
            "Бүтээгдэхүүн ашиглах газар:" : "Place of use of the product:",
            "Хүргэлт:": "Delivery type",
            "Утас:": "Phone number",
            "И-мэйл хаяг:": "Email:",
            "Тоо":"Quantity",
            "Захиалгыг дахин сагслах": "Reorder",
            "Төлөв": "Status",
            "Сагсанд нэмэх": "Add to cart",
            "Барааны хэмжээ сонгоно уу!": "Select the product quantity"
        }
    }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "mn", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;