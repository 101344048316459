import React from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import noImage from '../../Images/noImage.png';

const Product = (props) => {

    const navigate = useNavigate();

    const onClickItem = (p_id) => {
        navigate("/products/" + p_id);
    }

    let tMinPrice = 0;
    if (props.data?.producttype && typeof props.data?.producttype == 'object') {
        Object.keys(props.data?.producttype)?.map(key => {
            if (!isNaN(parseFloat(props.data?.producttype[key]?.price))) {
                if (tMinPrice == 0) {
                    tMinPrice = parseFloat(props.data?.producttype[key]?.price);
                } else if (tMinPrice > parseFloat(props.data?.producttype[key]?.price)) {
                    tMinPrice = parseFloat(props.data?.producttype[key]?.price);
                }
            }
        });
    }

    return (
        <div className='productContainer' style={{ marginLeft: 16, marginRight: 16 }} >
            <div style={{ cursor: 'pointer', background: '#FFFFFF', border: '1px solid #E7E7E7', overflow: 'hidden' }} onClick={() => onClickItem(props.data.id)}>
                {props.data?.img ? (
                    <div className="loadingImage">
                        <img key={props.data?.img} className='productImage' src={props.data?.img} alt="" loading="eager" />
                    </div>
                ) : (
                    <img className='productImage' src={noImage} alt="" />
                )}
            </div>
            <div style={{ cursor: 'pointer', textAlign: 'center', marginTop: 16, fontWeight: 500, fontSize: 14, color: '#212121' }} onClick={() => onClickItem(props.data.id)}>
                {props.data?.name}
            </div>
            {props.data?.price > 0 ? (props.data.isdiscount ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='fontMid' style={{ textAlign: 'center', marginTop: 16, marginRight: 8, fontWeight: 400, color: '#767676', textDecorationLine: 'line-through' }}>
                        <NumberFormat value={props.data?.oprice} displayType={'text'} thousandSeparator={true} suffix={' ₮'} />
                    </div>
                    <div className='fontLarge' style={{ textAlign: 'center', marginTop: 12, fontWeight: 600, color: '#FFA300' }}>
                        <NumberFormat value={props.data?.price} displayType={'text'} thousandSeparator={true} suffix={' ₮'} />
                    </div>
                </div>
            ) : (
                <div className='fontLarge' style={{ textAlign: 'center', marginTop: 12, fontWeight: 600, color: '#767676' }}>
                    <NumberFormat value={props.data?.price} displayType={'text'} thousandSeparator={true} suffix={' ₮'} />
                </div>
            )) : (
                tMinPrice > 0 && (
                    <div className='fontLarge' style={{ textAlign: 'center', marginTop: 12, fontWeight: 600, color: '#767676' }}>
                        <NumberFormat value={tMinPrice} displayType={'text'} thousandSeparator={true} suffix={' ₮'} />
                    </div>
                )
            )}
        </div>
    );
}

export default Product;