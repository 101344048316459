import React, { useEffect, useState } from 'react';
import { DatePicker } from '@fluentui/react';
import moment from 'moment';

const FieldDate = (props) => {

    const [value, setValue] = useState();

    useEffect(() => {
        let tValue = null;
        if (props.defaultValue) {
            tValue = moment(props.defaultValue);
        }
        setValue(tValue);
    }, [props.defaultValue]);

    const onFormatDate = (date) => {
        return !date ? '' : moment(date).format("YYYY.MM.DD");
    };

    const onChangeDate = (p_date) => {
        if (p_date) {
            props.onChanged(moment(p_date).format("YYYY.MM.DD"), '', props.keyfield);
        } else {
            props.onChanged(null, '', props.keyfield);
        }
    }

    return (
        <div>
            <div style={{ marginTop: 9 }}>
                <DatePicker
                    formatDate={onFormatDate}
                    borderless={false}
                    allowTextInput
                    onSelectDate={onChangeDate}
                    value={value}
                    disabled={props.disable == true ? true : false}
                    placeholder={props.placeholder}
                    styles={{
                        root: {
                            '.ms-TextField-fieldGroup': {
                                border: '1px solid #DDDDDD',
                                height: 48,
                                borderRadius: 24,
                                padding: '8px 16px'
                            }
                        }
                    }}
                />
                {(props.required && !value && props.checkreq) ? (
                    <div style={{ color: 'rgb(164, 38, 44)', fontSize: 12, fontWeight: 400, paddingTop: 5 }}>
                        {props.title + (props.lang == "en" ? "" : " талбарын утга хоосон байна.")}
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default FieldDate;